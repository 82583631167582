<template>
  <v-row>
    <v-col cols="12">
      <h4 class="text-uppercase">Cart Goal</h4>
    </v-col>
    <!-- {{ config }} -->
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.cart_goal.settings.isShow"
          label="Enable Cart Goal"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-divider class=""></v-divider>
    <v-col cols="12">
      <div class="form-component mt-2">
        <editor-new :dataT.sync="config.cart_goal.settings.motivationalMessage" :title="`Motivational message`" />
      </div>
      <div class="form-component mt-10">
        <label for="">Goal amount </label>
        <v-text-field
          v-model.number="config.cart_goal.settings.goalAmount"
          type="number"
          @input="$parent.changeConfig"
          autocomplete="new-password"
          hide-details=""
          id="id"
        ></v-text-field>
      </div>
      <div v-if="nameTheme === 'newchick'" class="form-component mt-10">
        <editor-new :dataT.sync="config.cart_goal.settings.goalReached" :title="`Goal reached`" />
      </div>
    </v-col>
    <div v-if="nameTheme === 'newchick'">
      <v-divider class=""></v-divider>
      <v-col cols="12">
        <h4 class="text-uppercase">Cart Upsell</h4>
      </v-col>
      <v-col cols="12">
        <div class="">
          <v-checkbox
            @change="$parent.changeConfig"
            v-model="config.cart_upsell.settings.isShowMotivational"
            label="Show Motivational Message"
            class="option-checkbox-height"
            hide-details=""
          ></v-checkbox>
        </div>
      </v-col>
      <v-col cols="12">
        <editor-new :dataT.sync="config.cart_upsell.settings.motivationalMessage" :title="``" />
      </v-col>
      <v-col cols="12">
        <div class="">
          <v-checkbox
            @change="$parent.changeConfig"
            v-model="config.cart_upsell.settings.isShowCartUpsell"
            label="Show Cart Upsell Message"
            class="option-checkbox-height"
            hide-details=""
          ></v-checkbox>
        </div>
      </v-col>
      <v-col cols="12">
        <editor-new :dataT.sync="config.cart_upsell.settings.cartUpsellMessage" :title="``" />
      </v-col>
      <v-col cols="12">
        <div class="form-component mt-10">
          <label for="">Button Label</label>
          <v-text-field
            v-model="config.cart_upsell.settings.buttonLabel"
            @input="$parent.changeConfig"
            autocomplete="new-password"
            hide-details=""
            id="id"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12">
        <label class="mt-22 d-inline-block" for="">Button link </label>
        <select-link
          :isCategory="true"
          :dataUrl="config.cart_upsell.settings.url"
          :itemSetData="config.cart_upsell.settings"
          @route="getRoute"
        />
      </v-col>
    </div>
  </v-row>
</template>

<script>
import SelectLink from '../driver-theme64/SelectLink.vue';
import EditorNew from '../EditorNew.vue';
export default {
  components: { EditorNew, SelectLink },
  name: 'cart-setting',
  data() {
    return {
      config: {},
    };
  },
  props: {
    configSection: {
      type: Object,
      default: () => {},
    },
    nameTheme: {
      type: String,
      default: '',
    },
  },
  methods: {
    getRoute(nameRoute, item) {
      item.url = nameRoute;
      this.$parent.changeConfig();
    },
    changeContent() {
      this.$parent.changeConfig();
    },
    getImageTrustBadge(image) {
      this.config.trust_badge.src.value = image.src;
      this.$parent.changeConfig();
    },
  },
  created() {
    this.config = this.configSection;
    console.log('this.configSection', this.configSection);
  },
};
</script>

<style></style>
