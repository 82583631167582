<template>
  <v-row no-gutters>
    <v-chip
      v-if="buttonLink.parent"
      class="ma-2"
      :close="!isCategory"
      label
      outlined
      @click:close="(buttonLink.parent = null), (buttonLink.slug = null)"
    >
      {{ buttonLink.parent === 'category' ? 'Collection' : 'Home' }}
    </v-chip>
    <v-chip v-if="buttonLink.slug" class="ma-2" close label outlined @click:close="buttonLink.slug = null">
      {{
        buttonLink.slug === 'all-lattehub-c'
          ? 'All collections'
          : buttonLink.slug
              .replace('-', ' ')
              .replace('_', ' ')
              .replace('_', ' ')
              .charAt(0)
              .toUpperCase() +
            buttonLink.slug
              .replace('-', ' ')
              .replace('-', ' ')
              .replace('-', ' ')
              .replace('-', ' ')
              .replace('_', ' ')
              .replace('_', ' ')
              .slice(1)
      }}
    </v-chip>
    <v-select
      v-model="buttonLink.parent"
      v-if="!buttonLink.parent"
      clearable
      @change="changParent"
      :items="links"
      item-text="name"
      item-value="value"
    ></v-select>
    <v-combobox
      v-model="buttonLink.slug"
      v-if="buttonLink.parent && !buttonLink.slug && buttonLink.parent !== 'home'"
      clearable
      :items="linksSlug"
      @update:search-input="searchCollection"
      :return-object="false"
      item-text="name"
      item-value="value"
    ></v-combobox>
  </v-row>
</template>

<script>
import { apiThemes } from '@/apis/theme.js';
import { collectionApi } from '@/apis/collection';

import options from './test';
export default {
  props: {
    itemSetData: {
      type: Object,
      default: () => {},
    },
    isCategory: {
      type: Boolean,
      default: false,
    },
    dataUrl: {
      type: Object,
      default: null,
    },
    isAllCollection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonLink: {
        parent: null,
        slug: null,
      },
      executor: null,
      linksSlug: [],
      links: [
        { name: 'Home page', value: 'home' },
        // { name: 'Pages', value: 'pages' },
        { name: 'Collection', value: 'category' },
      ],
      options,
      keySearch: '',
    };
  },
  watch: {
    'buttonLink.slug': function() {
      if (!this.buttonLink.slug) {
        this.$emit('route', { name: this.buttonLink.parent, slug: null }, this.itemSetData);
      }
    },
    buttonLink: {
      handler() {
        if (this.buttonLink.parent) {
          if (this.buttonLink.parent !== 'home' && this.buttonLink.slug) {
            this.$emit(
              'route',
              { name: this.buttonLink.parent, params: { slug: this.buttonLink.slug } },
              this.itemSetData,
            );
          }
          if (this.buttonLink.parent === 'home') {
            this.$emit('route', { name: 'home' }, this.itemSetData);
          }
        } else {
          this.$emit(`route`, { name: 'home' }, this.itemSetData);
        }
      },
      deep: true,
    },
  },
  methods: {
    searchCollection(e) {
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }
      this.executor = setTimeout(async () => {
        await this.loadData(e);
      }, 300);
    },
    async changParent(e) {
      if (e) {
        if (e === 'pages') this.linksSlug = [{ name: 'FAQ', value: 'faq' }];
        if (e === 'category') {
          this.loadData();
        }
      }
      console.log(e);
    },
    async loadData(e) {
      try {
        let res = await collectionApi.get({ search: e ? e : '' });
        this.linksSlug = res.data.collections.map(item => {
          return {
            name: item.title,
            value: item.handle,
          };
        });
        if (this.isAllCollection) {
          this.linksSlug = [{ name: 'All collection', value: 'all-lattehub-c' }].concat(this.linksSlug);
        }
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    if (this.dataUrl && typeof this.dataUrl === 'object') {
      this.buttonLink.parent = this.dataUrl.name;
      if (this.dataUrl.params && this.dataUrl.params.slug && this.dataUrl.params.slug !== '') {
        this.buttonLink.slug = this.dataUrl.params.slug;
        this.loadData();
      } else {
        this.buttonLink.slug = null;
      }
    }
    if (this.isCategory) {
      this.buttonLink.parent = 'category';
      if (this.linksSlug.length === 0) {
        this.loadData();
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
