<template>
  <div>
    <v-row class="form-component" v-if="nameTheme === 'newchick'">
      <v-col cols="12" class="pb-0">
        <h4 class="text-uppercase">Time countdown</h4>
      </v-col>
      <v-col cols="4" class="pb-0">
        <label for="">Day</label>
        <v-text-field height="auto" type="number" v-model.number="config.time.day" @input="setTimme()"></v-text-field>
      </v-col>
      <v-col cols="4" class="pb-0">
        <label for=""> Hour</label>
        <v-text-field height="auto" type="number" v-model.number="config.time.hour" @input="setTimme()"></v-text-field>
      </v-col>
      <v-col cols="4" class="pb-0">
        <label for="">Minute</label>
        <v-text-field height="auto" type="number" v-model.number="config.time.min" @input="setTimme()"></v-text-field>
      </v-col>
      <v-col cols="12" class="pb-0">
        <h4 class="text-uppercase mb-2">Checkout description</h4>
        <v-textarea v-model="config.checkout_description" @change="$parent.changeConfig"></v-textarea>
      </v-col>
      <v-col cols="12" class="pb-0">
        <h4 class="text-uppercase">Show time countdown</h4>
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_time_countdown"
          label="Show time countdown"
          class="option-checkbox-height-policy mt-2"
        ></v-checkbox>
      </v-col>
    </v-row>
    <!-- <v-row class="form-component">
      <v-col cols="12">
        <h4 class="text-uppercase">Checkout Step</h4>
      </v-col>
      <v-col cols="12">
        <v-radio-group v-model="config.checkoutStepLayout">
          <v-radio :label="`One Step`" :value="1"></v-radio>
          <v-radio :label="`Three Step`" :value="3"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.isShowShippingOptions"
          label="Show Shipping Options"
          class="option-checkbox-height"
        ></v-checkbox>
      </v-col>
    </v-row> -->
    <!-- <v-row class="form-component" v-if="nameTheme === 'hydro'">
      <v-col cols="12">
        <h4 class="text-uppercase">Checkout Step</h4>
      </v-col>
      <v-col cols="12">
        <v-radio-group v-model="config.step">
          <v-radio :label="`One Step`" :value="1"></v-radio>
          <v-radio :label="`Three Step`" :value="3"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row> -->
    <v-row class="form-component">
      <!-- {{ config.isCustomerNote }} -->
      <v-col cols="12" class="pb-0">
        <h4 class="text-uppercase">Customer's note</h4>
        <v-switch v-model="config.isCustomerNote"></v-switch>
      </v-col>
    </v-row>
    <v-row class="form-component">
      <v-col cols="12" class="mb-4">
        <h4 class="text-uppercase mb-2">Logo checkout</h4>
        <UploadOneImage :image="{ src: config.logo_checkout }" @callback="getImageTrustBadge" />
      </v-col>
      <v-col cols="12">
        <h4 class="mb-2" style="font-size: 16px;">
          Put your custom JavaScript code here to add and change some content of Checkout pages.
        </h4>
        <v-textarea v-model="config.customJs"></v-textarea>
      </v-col>
      <v-col cols="12">
        <h4 class="mb-2" style="font-size: 16px;">
          Put your custom HTML code here to customize Checkout pages' footer.
        </h4>
        <editor-new :dataT.sync="config.customHtml" :title="``" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EditorNew from '../EditorNew.vue';
export default {
  components: { EditorNew },
  props: {
    configSection: {
      type: Object,
      default: () => {},
    },
    nameTheme: {
      type: String,
      default: 'newchick',
    },
  },
  data() {
    return {
      config: {},
      time: {
        day: 0,
        hour: 0,
        min: 0,
      },
    };
  },
  created() {
    this.config = this.configSection;
    if (!this.config.logo_checkout) {
      this.config.logo_checkout = null;
    }
  },
  methods: {
    getImageTrustBadge(image) {
      console.log('image', image);
      this.config.logo_checkout = image.src;
      this.$parent.changeConfig();
    },
    setTimme() {
      this.config.time.getTime =
        new Date().getTime() +
        (this.config.time.day * 24 * 60 * 60 + this.config.time.hour * 60 * 60 + this.config.time.min * 60) * 1000;
      this.$forceUpdate();
    },
  },
};
</script>

<style></style>
