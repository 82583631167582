var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('label',{staticClass:"mb-1 fz-14",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('editor',{attrs:{"api-key":"cp1okc1v5mgid311kk2qxto3hn01wllmxcduuffi34b50rwr","init":{
      toolbar_mode: 'wrap',
      toolbar_sticky: true,

      selector: 'textarea',
      menubar: false,
      plugins:
        'noneditable autoresize print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable',

      toolbar:
        'bold italic underline strikethrough  |  link  | lineheight | alignleft aligncenter alignright alignjustify | forecolor backcolor  | fontselect fontsizeselect code',
      fontsize_formats: '8px 9px 10px 11px 12px 14px 16px 18px 24px 30px 36px 48px 60px 72px 96px',
      max_height: _vm.height,
      min_height: _vm.height,
      lineheight_formats: '0.1 0.2 0.5 0.75 1 1.1 1.2 1.3 1.4 1.5 2',
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      /* without images_upload_url set, Upload tab won't show up*/
      /* we override default upload handler to simulate successful upload*/
      browser_spellcheck: true,
      images_upload_handler: _vm.example_image_upload_handler,
      noneditable_noneditable_class: 'uneditable',
    }},on:{"input":function($event){return _vm.$emit('update')},"onSelectionChange":_vm.handlerFunction},model:{value:(_vm.html),callback:function ($$v) {_vm.html=$$v},expression:"html"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }