import { apiThemes } from '@/apis/theme.js';
/* eslint-disable */

export default [
  {
    label: 'Group',
    field: 'group',
    operators: ['='],
    options: async () => {
      return new Promise(async resolve => {
        try {
          let res = await apiThemes.getConfig('601be73238567e340105e8d9');
          return resolve(res.data);
        } catch (e) {
          console.log(e);
          return resolve(null);
        }
      });
    },
  },
];
