<template>
  <v-row>
    <!-- <v-col cols="12" class="py-0"><h4 class="text-uppercase">Product photos</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.enable_image_zoom"
          label="Enable image zoom"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
   
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Product stock</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_item_stock"
          label="Show items left in stock"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col> -->
    <!-- <v-col cols="12" class="py-0"><h4 class="text-uppercase">Product collection</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_collection"
          label="Show collection"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col> -->
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase mb-2">Product rating</h4>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.productRating"
        label="Show product rating"
        class="option-checkbox-height"
      />
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.hideReviewSizes"
        label="Hide review sizes"
        class="option-checkbox-height"
      />
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase mb-2">Color Rating</h4>
      <color-picker v-model="config.colorProductRating"></color-picker>
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase mb-2">Color Buy X get Y</h4>
      <color-picker v-model="config.colorByXGetY"></color-picker>
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase mb-2">shape color watch</h4>
      <v-combobox
        @change="$parent.changeConfig"
        v-model="config.color_watch"
        :items="['Square', 'Circle']"
        class="option-checkbox-height-policy text-captalize"
      ></v-combobox>
    </v-col>
    <v-col cols="12" class="pt-0">
      <h4 class="text-uppercase mb-2">Images product mobile</h4>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.isShowImageMobile"
        label="Show image"
        class="option-checkbox-height"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" class="pt-0">
      <h4 class="text-uppercase mb-2">Product tags</h4>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.tags.show_tags"
        label="Show tags"
        class="option-checkbox-height"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" class="py-0">
      <h4 class="text-uppercase mb-2">Variant buttons</h4>
      <v-text-field type="number" v-model="config.layout.variant_buttons" label="Variant buttons" />
    </v-col>
    <v-col :cols="12" class="py-0">
      <h4 class="text-uppercase mb-2">Checkout buttons</h4>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.isShowPaypalButton"
        label="Show paypal checkout button"
        class="option-checkbox-height"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" class="pt-0">
      <h4 class="text-uppercase mb-2">Variant description</h4>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.show_description_variant"
        label="Show variant description"
        class="option-checkbox-height"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" class="py-0">
      <h4 class="text-uppercase mb-2">Product title position</h4>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.layout.show_title_on_top_mobile"
        label="Show product title on top on mobile"
        class="option-checkbox-height mt-2"
        id="show_title_on_top_mobile"
      ></v-checkbox>
    </v-col>
    <!-- <v-col cols="12" class="py-0">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase">Layout</h4>
    </v-col>
    <v-col cols="12" class="py-5">
      <div class="">
        <v-checkbox
          class="option-checkbox-height"
          @change="$parent.changeConfig"
          v-model="config.show_label_variant"
          label="Show label variant"
        ></v-checkbox>
      </div>
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.layout.show_price_saving"
          label="Show price savings"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
      <div class="">
        <v-checkbox
          class="option-checkbox-height"
          @change="$parent.changeConfig"
          v-model="config.layout.special_layout_on_mobile"
          label="Enable special layout on mobile devices for products with custom options"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="pb-0">
      <label for="" class="font-weight-bold h4">Sale style</label>
      <v-select
        color
        @change="$parent.changeConfig"
        v-model="config.layout.sale_type"
        item-text="name"
        item-value="id"
        class="select-text"
        :items="[
          { name: 'Amount', id: 'amount' },
          { name: 'Percentage', id: 'percent' },
        ]"
      ></v-select>
    </v-col>
    <v-col cols="12" class="pt-0">
      <label for="" class="font-weight-bold h4">Variant style</label>
      <v-select
        color
        @change="$parent.changeConfig"
        v-model="config.layout.variant_style"
        item-text="name"
        item-value="id"
        class="select-text"
        :items="[
          { name: 'Button', id: 'button' },
          { name: 'Dropdown', id: 'dropdown' },
        ]"
      ></v-select>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_qty.show_qty_box"
          label="Show quantity box"
          class="option-checkbox-height"
        ></v-checkbox>
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_qty.show_qty_one_line"
          label="Show quantity box and Add to cart button"
          class="option-checkbox-height"
        ></v-checkbox>
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_qty.show_qty_same_line_button"
          label="Show quantity box in the same line with Add to cart button / Buy now button"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="pb-0">
      <label for="" class="font-weight-bold h4">Description position</label>
      <v-select
        color
        @change="$parent.changeConfig"
        v-model="config['position_tab_detail']"
        item-text="name"
        item-value="id"
        class="select-text"
        :items="[
          { name: 'Description in the right', id: 'right' },
          { name: 'Description below product form', id: 'below' },
        ]"
      ></v-select>
    </v-col>
    <v-col cols="12" class="py-0">
      <label for="" class="font-weight-bold h4">Product title position</label>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.layout.show_title_on_top_mobile"
        label="Show product title on top on mobile"
        class="option-checkbox-height"
      ></v-checkbox>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase">Option settings</h4>
    </v-col>
    <v-col cols="12" class="py-4">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.options_setting.hidden_one_value"
          label="Hide selector when option has one value"
          class="option-checkbox-height"
        ></v-checkbox>
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.options_setting.click_select_variant"
          label="Select variants by clicking their images"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
      <div>
        <p class="mb-0">Each image must be attached to a variant for this option to work.</p>
      </div>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="pb-0">
      <h4>Tabs</h4>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-expansion-panels accordion class="list-section" style="max-height: 1100px !important">
      <draggable v-model="config['tabs-detail']" @change="change" style="width: 100%">
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config['tabs-detail']" :key="`item${i}`">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
              <i class="fas fa-bars mr-2" style="flex: none !important"></i>

              {{ item.heading }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <label class="mt-5 d-inline-block" for="">Heading</label>
              <v-text-field v-model="item.heading" height="auto" @input="$parent.changeConfig"></v-text-field>
              <EditorCustom
                :content.sync="item.content"
                @change="changeContent"
                v-if="item.display === 'html'"
                height="auto"
              ></EditorCustom>
              <div class="my-3">
                <span
                  v-if="item.display !== 'description'"
                  class="pointer font-weight-bold red--text"
                  @click="removeContent(i)"
                >
                  Delete tab
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12" v-if="config['tabs-detail'].length < maxBlock">
      <span class="pointer font-weight-bold primary--text" @click="addContent">+ Add tabs</span>
    </v-col>
    <v-col cols="12" class="d-flex max-block" v-else>
      <v-icon small color="grey lighten-1">
        mdi-plus-box
      </v-icon>
      <div class="pl-4">
        <p class="pointer font-weight-bold primary--text  mb-0">
          {{ config['tabs-detail'].length }}/{{ maxBlock }} blocks used.
        </p>
        <p class="mb-0">Delete to add more.</p>
      </div>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-divider class=""></v-divider>
    </v-col> -->
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase mb-2">policy shipping</h4>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.setting_shipping.show_shipping"
        label="Show policy shipping"
        class="option-checkbox-height-policy"
      ></v-checkbox>
    </v-col>
    <v-col cols="12">
      <EditorCustom
        :content.sync="config.setting_shipping.shipping_info.content"
        :title="config.setting_shipping.title"
        @change="changeContent"
        v-if="config.setting_shipping.shipping_info.display === 'html'"
        height="auto"
      ></EditorCustom>
    </v-col>
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase mb-2">Range shipping</h4>
      <label class="mt-2 d-inline-block" for="">From:</label>
      <v-slider
        v-model.number="config.setting_shipping.time_shipping.from"
        class="pointer"
        max="100"
        min="0"
        @input="$parent.changeConfig"
      >
        <template v-slot:append>
          <p>{{ config.setting_shipping.time_shipping.from }} days</p>
        </template>
      </v-slider>
      <label class="mt-2 d-inline-block" for="">To: </label>
      <v-slider
        v-model.number="config.setting_shipping.time_shipping.to"
        class="pointer"
        max="100"
        min="0"
        @input="$parent.changeConfig"
      >
        <template v-slot:append>
          <p>{{ config.setting_shipping.time_shipping.to }} days</p>
        </template>
      </v-slider>
    </v-col>
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase">Page Shipping</h4>
      <v-select
        color
        v-model="config.setting_shipping.shipping_info.page_detail"
        :items="pages"
        item-text="title"
        item-value="handle"
      ></v-select>
    </v-col>
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase mb-2">policy Return</h4>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.setting_shipping.show_policy"
        label="Show return policy"
        class="option-checkbox-height-policy"
      ></v-checkbox>
    </v-col>
    <v-col cols="12">
      <EditorCustom
        :content.sync="config.setting_shipping.policy_info.content"
        :title="config.setting_shipping.title"
        @change="changeContent"
        v-if="config.setting_shipping.policy_info.display === 'html'"
        height="auto"
      ></EditorCustom>
    </v-col>
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase mb-2">Page Return</h4>
      <v-select
        color
        v-model="config.setting_shipping.policy_info.page_detail"
        :items="pages"
        item-text="title"
        item-value="handle"
      ></v-select>
    </v-col>
    <!-- <v-col cols="12">
      <h4 class="text-uppercase">Heading description</h4>
      <v-text-field @change="$parent.changeConfig" v-model="config.heading"></v-text-field>
    </v-col> -->
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase mb-2">Setting description</h4>
      <v-divider class="mb-4"></v-divider>
      <v-checkbox
        v-model="config.isShowDesMobile"
        @input="$parent.changeConfig"
        label="Show description on mobile"
        class="option-checkbox-height"
      ></v-checkbox>
      <v-checkbox
        v-model="config.collapse_default"
        @input="$parent.changeConfig"
        label="Show description on desktop"
        class="option-checkbox-height"
      ></v-checkbox>
      <v-checkbox
        v-model="config.description_mobile_dropdown"
        @input="$parent.changeConfig"
        label="Dropdown description in mobile"
        class="option-checkbox-height"
      ></v-checkbox>
    </v-col>
    <v-expansion-panels v-model="panel" accordion class="list-section" style="max-height: 1100px !important">
      <draggable v-model="config.setting_description" style="width: 100%">
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config.setting_description" :key="`item${i}`">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
              <i class="fas fa-bars mr-2" style="flex: none !important"></i>{{ item.heading }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <div>
                <label class="mt-5 d-inline-block font-weight-bold" for="" v-if="item.id === 'Description'">
                  Heading Description
                </label>
                <v-text-field height="auto" v-model="item.heading" @input="$parent.changeConfig"></v-text-field>
              </div>
              <div v-if="item.id !== 'Description'">
                <!-- <EditorCustom
                  :content.sync="item.content"
                  @change="changeContent"
                  v-if="item.display === 'html'"
                  height="500px"
                ></EditorCustom> -->
                <EditorNew :dataT.sync="item.content" :title="`Sub Title`" :height="500"></EditorNew>
              </div>
              <div v-if="item.id !== 'Description'">
                <span class="pointer font-weight-bold red--text" @click="removeContentDes(i)"> Remove content </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12" class="mt-2">
      <v-btn @click="addContentDes" color="primary" :disabled="config.setting_description.length >= 3">
        + Add Tab
      </v-btn>
    </v-col>
    <!-- <v-col cols="12">
      <div class="">
        <v-checkbox
          v-model="config.collapse_default"
          @input="$parent.changeConfig"
          label="Collapse this tab by default"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col> -->
    <v-col cols="12">
      <v-divider class="mb-4"></v-divider>
      <h4 class="text-uppercase mb-3">Image Detail</h4>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.image_detail.isShow"
        label="Show image detail"
        class="option-checkbox-height-policy"
      ></v-checkbox>
      <UploadOneImage :image="{ src: config.image_detail.image }" @callback="getImageDetail" />
    </v-col>
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase mb-2">Button link</h4>
      <select-link
        :isCategory="true"
        :dataUrl="config.image_detail.url"
        :itemSetData="config.image_detail"
        @route="getRouteImage"
      />
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase mb-3">Setting ATC/BuyNow</h4>
      <v-radio-group v-model="config.checkoutStepLayout">
        <v-radio :label="`To CartPage`" :value="1"></v-radio>
        <v-radio :label="`To Checkout Page`" :value="2"></v-radio>
        <v-radio :label="`Open mini Cart Page `" :value="3"></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import settingDataDefault from '../../setting-data-newchic.json';
import EditorCustom from '../driver-theme64/EditorCustom.vue';
import EditorNew from '../EditorNew.vue';
import { pageService } from '@/apis/page.s';
import SelectLink from '../driver-theme64/SelectLink.vue';
import ColorPicker from '../driver-theme64/ColorPicker.vue';
export default {
  components: {
    draggable,
    EditorCustom,
    SelectLink,
    EditorNew,
    ColorPicker,
  },
  name: 'product-setting',
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      pages: [],
      config: {},
      data: settingDataDefault,
      maxBlock: 5,
      panel: [0],
    };
  },
  methods: {
    getRouteImage(nameRoute, item) {
      item.url = nameRoute;
      this.$parent.changeConfig();
    },
    getImageDetail(image) {
      this.config.image_detail.image = image.src;
      this.$parent.changeConfig();
    },
    async getPage() {
      try {
        let res = await pageService.list();
        this.pages = res.data.pages.filter(it => it.published);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    changeContent() {
      this.$parent.changeConfig();
    },
    removeContent(i) {
      this.$parent.settings['product']['tabs-detail'].splice(i, 1);
      this.$parent.changeConfig();
    },
    addContent() {
      this.$parent.settings['product']['tabs-detail'].push({
        heading: 'Tab new',
        id: Math.random(),
        type: 'custom',
        display: 'html',
        content: '',
      });
      this.$parent.changeConfig();
    },
    getRoute(nameRoute, item) {
      item.url_button = nameRoute;
      this.$parent.changeConfig();
    },
    getImage(images, item) {
      item.image = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    change() {
      this.$parent.changeConfig();
    },
    getImageTrustBadge(image) {
      this.config.trust_badge.src.value = image.src;
      this.$parent.changeConfig();
    },
    addContentDes() {
      let item = {
        heading: 'Heading',
        id: Math.random(),
        display: 'html',
        content: '',
      };
      this.config.setting_description.push(item);
      this.panel[0] = this.config.setting_description.length - 1;
      this.$parent.changeConfig();
    },
    removeContentDes(i) {
      this.config.setting_description.splice(i, 1);
      this.panel[0] = this.config.setting_description.length - 1;
      this.$parent.changeConfig();
    },
    update(dataSet, html) {
      dataSet.content = html;
      this.$parent.changeConfig();
      // console.log(html);
    },
  },
  created() {
    this.config = this.configSection;
    if (this.config.setting_shipping && !this.config.setting_shipping.time_shipping) {
      this.config.setting_shipping.time_shipping = this.data.settings.product.setting_shipping.time_shipping;
    }
    if (!this.config.color_watch) {
      this.config.color_watch = 'Square';
    }
    if (!this.config.setting_description) {
      this.config.setting_description = [];
      let item = {};
      if (this.config.heading) {
        item.heading = this.config.heading;
        item.id = 'Description';
        item.content = '';
      }
      this.config.setting_description.push(item);
    }
    this.getPage();
  },
};
</script>

<style lang="scss" scoped>
* {
  font-size: 14px;
}
.option-checkbox-height {
  height: 40px;
}
.v-input--selection-controls {
  padding: 0px;
  margin: 0px;
}
.max-block {
  background-color: #f8f8fc;
}
</style>
