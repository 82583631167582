<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <h4 class="text-uppercase">Menu type</h4>
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.menu_basic"
          label="Menu basic"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="py-0 pt-5">
      <h4 class="text-uppercase">Title product list in mobile</h4>
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_title_product_in_mobile"
          label="Enable title product in list"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="py-0 pt-5">
      <h4 class="text-uppercase">Search mobile</h4>
    </v-col>
    <v-col cols="12">
      <label for="" class="font-weight-bold h4">Type search in mobile</label>
      <div class="">
        <v-radio-group v-model="config.search.type_search_in_mobile">
          <v-radio label="Icon" value="icon"></v-radio>
          <v-radio label="Input" value="input"></v-radio>
        </v-radio-group>
      </div>
    </v-col>
    <v-col v-if="config.search.type_search_in_mobile === 'input'">
      <label for="" class="font-weight-bold h4">List search suggestion</label>
      <v-expansion-panels accordion class="list-section" style="max-height: 1100px !important">
        <draggable v-model="config.search.suggestion_list" style="width: 100%">
          <transition-group>
            <v-expansion-panel v-for="(item, i) in config.search.suggestion_list" :key="`item${i}`">
              <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
                {{ `Suggestion` }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-text-field v-model="item.value" height="auto" @input="$parent.changeConfig"></v-text-field>
                <!-- <label class="mt-2 d-inline-block" for="">Content</label> -->
                <div class="my-3">
                  <span class="pointer font-weight-bold red--text" @click="removeContent(i)">
                    Delete suggestion
                  </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </transition-group>
        </draggable>
      </v-expansion-panels>
      <div class="my-3">
        <span class="pointer font-weight-bold red--text" @click="addContent">
          Add suggestion
        </span>
      </div>
    </v-col>
    <v-col cols="12" v-if="config.search.type_search_in_mobile === 'input'">
      <label for="" class="font-weight-bold h4">Time change suggestion</label>
      <v-slider
        v-model="config.search.time_change_suggestion"
        min="1"
        max="5"
        inverse-label
        :label="`${config.search.time_change_suggestion}s`"
      ></v-slider>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable';
export default {
  components: {
    draggable,
  },
  name: 'element-setting',
  props: {
    configSection: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
    };
  },
  methods: {
    removeContent(i) {
      console.log('remove');
      this.config.search.suggestion_list.splice(i, 1);
    },
    addContent() {
      console.log('add');
      let obj = {
        value: '',
      };
      this.config.search.suggestion_list.push(obj);
    },
  },
  created() {
    this.config = this.configSection;
  },
};
</script>

<style></style>
