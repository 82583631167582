<template>
  <div>
    <div>
      <div>Max line title list product</div>
      <v-text-field
        type="number"
        @change="$parent.changeConfig()"
        v-model.number="config.maxLineDisplayProduct"
      ></v-text-field>
    </div>
    <div>
      <div>Title product detail desktop</div>
      <v-text-field
        type="number"
        @change="$parent.changeConfig()"
        v-model.number="config.maxLineDisplayProductDetailDesktop"
      ></v-text-field>
    </div>
    <div>
      <div>Title product detail mobile</div>
      <v-text-field
        type="number"
        @change="$parent.changeConfig()"
        v-model.number="config.maxLineDisplayProductDetailMobile"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    configSection: {
      type: Object,
      default: () => {},
    },
    nameTheme: {
      type: String,
      default: 'newchick',
    },
  },
  created() {
    this.config = this.configSection;
  },
};
</script>

<style lang="scss" scoped></style>
