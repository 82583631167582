<template>
  <div style="margin-top: 15px" class="pa-3">
    <div class="form-component">
      <label for="">Logo</label>
      <UploadOneImage :image="{ src: $parent.pageFixedSettings.footer.settings.logo }" @callback="getImageDeskTop" />
    </div>
    <v-col cols="12">
      <div class="">
        Height desktop
        <v-text-field
          hide-details=""
          height="auto"
          v-model="$parent.pageFixedSettings.footer.settings.size_logo.desktop"
          @input="$parent.changeConfig()"
        ></v-text-field>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="">
        Height mobile
        <v-text-field
          hide-details=""
          height="auto"
          v-model="$parent.pageFixedSettings.footer.settings.size_logo.mobile"
          @input="$parent.changeConfig()"
        ></v-text-field>
      </div>
    </v-col>
    <v-divider class="my-4"></v-divider>
    <div class="form-component mt-2">
      <!-- <label for="">Address</label> -->
      <editor-new-noedit :dataT.sync="$parent.pageFixedSettings.footer.settings.info" :title="`Info`" />
      <!-- <editor-custom
        :content.sync="$parent.pageFixedSettings.footer.settings.info"
        :title="`Info`"
        @change="$parent.changeConfig()"
        height="500px"
      ></editor-custom> -->
    </div>

    <div class="form-component mt-7">
      <div class="d-flex justify-space-between">
        <label for="">Main Menu</label>
        <span class="pointer link-add" @click="$router.push({ name: 'website.menus' })">Edit Menu</span>
      </div>
      <v-select
        hide-details=""
        v-model="$parent.pageFixedSettings.footer.settings.footer_menu"
        :items="$parent.mainMenu"
        @input="$parent.changeConfig('menuFooter', 0)"
        item-text="title"
        item-value="id"
      ></v-select>
    </div>
    <v-row v-if="$parent.pageFixedSettings.footer.settings.infoWithIcon">
      <v-col cols="12">
        <h4>Info</h4>
      </v-col>
      <v-expansion-panels accordion class="list-section" style="max-height:1100px !important">
        <draggable
          v-model="$parent.pageFixedSettings.footer.settings.infoWithIcon"
          @change="change"
          style="width: 100%;"
        >
          <transition-group>
            <v-expansion-panel
              v-for="(item, i) in $parent.pageFixedSettings.footer.settings.infoWithIcon"
              :key="`item${i}`"
            >
              <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
                <i class="fas fa-bars mr-2" style="flex:none !important"></i>

                {{ 'Info Icon' }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <UploadOneImage :itemSetData="item" :image="{ src: item.icon }" @callback="getImageIcon" />
                <label class="mt-5 d-inline-block" for="">Title</label>
                <editor-new :dataT.sync="item.title" :title="`Title`" />
                <editor-new :dataT.sync="item.subTitle" :title="`Sub Title`" />
                <!-- <v-text-field height="auto" v-model="item.subTitle" @input="$parent.changeConfig"></v-text-field> -->
                <div class="mt-2">
                  <span class="pointer font-weight-bold red--text" @click="removeContentInfo(i)">
                    Remove Info
                  </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </transition-group>
        </draggable>
      </v-expansion-panels>
      <v-col v-if="$parent.pageFixedSettings.footer.settings.infoWithIcon" cols="12">
        <span class="pointer font-weight-bold primary--text" @click="addContentInfo">+ Add Info</span>
      </v-col>
    </v-row>
    <v-row v-if="$parent.pageFixedSettings.footer.settings.iconPayments">
      <v-col cols="12">
        <h4>Payments</h4>
      </v-col>
      <v-expansion-panels accordion class="list-section" style="max-height:1100px !important">
        <draggable
          v-model="$parent.pageFixedSettings.footer.settings.iconPayments"
          @change="change"
          style="width: 100%;"
        >
          <transition-group>
            <v-expansion-panel
              v-for="(item, i) in $parent.pageFixedSettings.footer.settings.iconPayments"
              :key="`item${i}`"
            >
              <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
                <i class="fas fa-bars mr-2" style="flex:none !important"></i>

                {{ 'Payment Icon' }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <UploadOneImage :itemSetData="item" :image="{ src: item.value }" @callback="getImage" />
                <div class="mt-2">
                  <span class="pointer font-weight-bold red--text" @click="removeContent(i)">
                    Remove icon
                  </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </transition-group>
        </draggable>
      </v-expansion-panels>
      <v-col v-if="$parent.pageFixedSettings.footer.settings.iconPayments" cols="12">
        <span class="pointer font-weight-bold primary--text" @click="addContent">+ Add Image Payment</span>
      </v-col>
    </v-row>
    <div class="form-component mt-2">
      <!-- <label class="mt-5 d-inline-block" for="">Copyright </label> -->
      <editor-custom
        :content.sync="$parent.pageFixedSettings.footer.settings.copyright"
        :title="`Copyright`"
        @change="$parent.changeConfig()"
        height="500px"
      ></editor-custom>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import EditorCustom from '../driver-theme64/EditorCustom.vue';
import EditorNew from '../EditorNew.vue';
import EditorNewNoedit from '../EditorNewNoedit.vue';

export default {
  components: {
    EditorCustom,
    draggable,
    EditorNew,
    EditorNewNoedit,
  },
  methods: {
    getImageDeskTop(image) {
      this.$parent.pageFixedSettings.footer.settings.logo = image.src;
      this.$parent.changeConfig('menu', 0);
    },
    getImage(images, item) {
      item.value = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    getImageIcon(images, item) {
      item.icon = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    removeContent(i) {
      this.$parent.pageFixedSettings.footer.settings.iconPayments.splice(i, 1);
      this.$parent.changeConfig();
    },
    change() {
      this.$parent.changeConfig();
    },
    addContent() {
      this.$parent.pageFixedSettings.footer.settings.iconPayments.push({
        value: null,
      });
    },
    removeContentInfo(i) {
      this.$parent.pageFixedSettings.footer.settings.infoWithIcon.splice(i, 1);
      this.$parent.changeConfig();
    },

    addContentInfo() {
      this.$parent.pageFixedSettings.footer.settings.infoWithIcon.push({
        icon: null,
        title: 'High-quality Goods',
        subTitle: 'Enjoy top quality items for less',
      });
      this.$parent.changeConfig();
    },
  },
};
</script>

<style lang="scss" scoped></style>
