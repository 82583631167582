<template>
  <div class="sidebar">
    <div v-if="nameTheme === 'hydro'">
      <div class="logo-desc d-flex align-center pointer" @click="$parent.isAddSection = false">
        <i class="fas fa-chevron-left mr-10"></i>
        <div class="fz-18">{{ 'AddSection' }}</div>
      </div>
      <v-divider></v-divider>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('collection_list')">
        <div class="title-collection-select">{{ 'Collection List' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('image_with_text')">
        <div class="title-collection-select">{{ 'Image with text' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('feature-content')">
        <div class="title-collection-select">{{ 'Feature content' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('head-image')">
        <div class="title-collection-select">{{ 'Head Image' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('product-review')">
        <div class="title-collection-select">{{ 'Product review' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('featured-collection')">
        <div class="title-collection-select">{{ 'Featured Collection' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
    </div>
    <div v-if="nameTheme === 'newchick'">
      <div class="logo-desc d-flex align-center pointer" @click="$parent.isAddSection = false">
        <i class="fas fa-chevron-left mr-10"></i>
        <div class="fz-18">{{ 'AddSection' }}</div>
      </div>
      <v-divider></v-divider>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('collection_list')">
        <div class="title-collection-select">{{ 'Collection List' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('head-image')">
        <div class="title-collection-select">{{ 'Head Image' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('style-collection')">
        <div class="title-collection-select">{{ 'Style Collection' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('product_with_collection')">
        <div class="title-collection-select">{{ 'Product With Collection' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('flash_deals')">
        <div class="title-collection-select">{{ 'Flash Deals' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="selection-add-collection ma-3" @click="callBackSubmitSelectAddSection('featured-collection')">
        <div class="title-collection-select">{{ 'Featured Collection' }}</div>
        <div class="icon-collection-select"><i class="fas fa-plus-circle"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    callBackSubmitSelectAddSection: {
      type: Function,
      default: () => {},
    },
    nameTheme: {
      type: String,
      default: 'newchick',
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
