<template>
  <div class="pa-2">
    <!-- <input type="text" v-model="image" />
              <button @click="above(1)">Doi anh</button> -->
    <div class="form-component">
      <label for="">TopBar</label>
      <v-checkbox
        class="mt-2"
        v-model="$parent.pageFixedSettings.header.block[0].config[0].value"
        @click="$parent.changeConfig('header', 0)"
        label="Show TopBar"
      ></v-checkbox>
    </div>
    <div class="form-component" v-if="$parent.pageFixedSettings.header.block[0].config[0].value">
      <label for="">Title TopBar</label>
      <v-textarea
        v-model="$parent.pageFixedSettings.header.block[0].config[1].value"
        autocomplete="new-password"
        @input="$parent.changeConfig('header', 0)"
        id="id"
      ></v-textarea>
    </div>

    <v-divider class="my-3"></v-divider>
    <div class="form-component mb-4">
      <label for="" class="d-block mb-2">Image DeskTop</label>
      <UploadOneImage
        :image="{ src: $parent.pageFixedSettings.header.block[1].config[0].value }"
        @callback="getImageDeskTop"
      />

      <!-- <images-uploader
        :images="[{ src: $parent.pageFixedSettings.header.block[1].config[0].value }]"
        @callback="$parent.getImageUpLoadLogoDesktop"
      /> -->
    </div>
    <div class="form-component mb-4">
      <label for="" class="d-block mb-2">Image Mobile</label>
      <UploadOneImage
        :image="{ src: $parent.pageFixedSettings.header.block[1].config[1].value }"
        @callback="getImageMobile"
      />
    </div>
    <div class="form-component">
      <v-checkbox
        class="mt-2"
        v-model="$parent.pageFixedSettings.header.settings.center_logo_on_mobile"
        @click="$parent.changeConfig('header', 0)"
        label="Center logo on mobile"
      ></v-checkbox>
    </div>
    <v-divider class="my-3"></v-divider>
    <div class="form-component">
      <div class="d-flex justify-space-between mb-2">
        <label for="">Main Menu</label>
        <span class="pointer link-add" @click="$router.push({ name: 'website.menus' })">Edit Menu</span>
      </div>
      <v-select
        v-model="$parent.pageFixedSettings.header.block[2].config[0].value"
        :items="$parent.mainMenu"
        @input="$parent.changeConfig('menu', 0)"
        item-text="title"
        item-value="id"
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getImageMobile(image) {
      this.$parent.pageFixedSettings.header.block[1].config[1].value = image.src;
      this.$parent.changeConfig('menu', 0);
    },
    getImageDeskTop(image) {
      this.$parent.pageFixedSettings.header.block[1].config[0].value = image.src;
      this.$parent.changeConfig('menu', 0);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep label.v-label {
  font-weight: unset;
}
</style>
