<template>
  <v-row>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Backgrounds</h4></v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Top bar Background</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_announcement_bar_background"></ColorPicker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Footer Background</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.footer_background"></ColorPicker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Product title color</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_title_product"></ColorPicker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Product icon color</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_product_icon"></ColorPicker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Color price</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_price"></ColorPicker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Color compare at price</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_compare_at_price"></ColorPicker>
    </v-col>
    <v-col v-if="nameTheme === 'hydro'" cols="12" class="px-5">
      <h4 class="">Color sale tag</h4>
    </v-col>
    <v-col v-if="nameTheme === 'hydro'" cols="12" class="pt-0">
      <ColorPicker v-model="config.color_sale_price"></ColorPicker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Color Button Addtocart</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_cart_button"></ColorPicker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Color Button Label Addtocart</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_cart_button_label"></ColorPicker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Color Button In Checkout Page</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_checkout_button"></ColorPicker>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">TEXT</h4></v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Announcement message text</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_announcement_bar_text"></ColorPicker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Footer text</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <ColorPicker v-model="config.color_footer_text"></ColorPicker>
    </v-col>
  </v-row>
</template>
<script>
import ColorPicker from './ColorPicker';
export default {
  name: 'colors-setting',
  components: {
    ColorPicker,
  },
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
    nameTheme: {
      name: String,
      default: 'newchick',
    },
  },
  data() {
    return {
      config: {},
      maxBlock: 5,
      backgrounds: {},
      menu: false,
      color: '#1976D2FF',
      mode: 'hex',
    };
  },
  methods: {
    changeContent() {
      this.$parent.changeConfig();
    },
    change() {
      this.$parent.changeConfig();
    },
    swatchStyle(color) {
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: '4px',
        transition: 'border-radius 200ms ease-in-out',
      };
    },
  },
  computed: {},
  created() {
    this.config = this.configSection;
    if (!this.config.color_checkout_button) {
      this.config.color_checkout_button = '#0082E5';
    }
  },
  mounted() {},
  watch: {
    config: {
      handler() {
        this.$parent.changeConfig();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
