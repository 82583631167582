<template>
  <div style="margin-top: 15px;" class="pa-3">
    <div class="form-component">
      <UploadOneImage
        :image="{ src: $parent.settingData.pages.test.block[0].config[0].value }"
        @callback="getImageDeskTop"
      >
      </UploadOneImage>
    </div>
    <v-divider class="my-4"></v-divider>
    <div class="form-component">
      <label for="">Text area</label>
      <v-textarea
        v-model="$parent.settingData.pages.test.block[0].config[1].value"
        autocomplete="new-password"
        hide-details=""
        id="id"
        @input="$parent.changeConfig('test', 0)"
      >
      </v-textarea>
    </div>
    <div class="form-component">
      <label for="">Text field</label>
      <v-text-field
        v-model="$parent.settingData.pages.test.label"
        autocomplete="new-password"
        hide-details=""
        id="id"
        @input="$parent.changeConfig('test', 0)"
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'test',
  methods: {
    getImageDeskTop(image) {
      this.$parent.settingData.pages.test.block[0].config[0].value = image.src;
      this.$parent.changeConfig('test', 0);
    },
  },
};
</script>
<style></style>
