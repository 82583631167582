<template>
  <div style="margin-top: 15px" class="pa-3">
    <div class="form-component" v-if="$parent.pageFixedSettings.footer.block[0].config[3]">
      <label for="">Logo</label>
      <UploadOneImage
        :image="{ src: $parent.pageFixedSettings.footer.block[0].config[3].value }"
        @callback="getImageDeskTop"
      />
      <!-- <images-uploader
        :images="[{ src: $parent.pageFixedSettings.footer.block[0].config[3].value }]"
        @callback="$parent.getImageUpLoadLogoFooter"
      /> -->
    </div>
    <v-divider class="my-4"></v-divider>
    <div class="form-component mt-2">
      <label for="">Address</label>
      <v-textarea
        v-model="$parent.pageFixedSettings.footer.block[0].config[0].value"
        @input="$parent.changeConfig('footer', 0)"
        autocomplete="new-password"
        hide-details=""
        id="id"
      ></v-textarea>
    </div>
    <div class="form-component mt-2">
      <label for="">Email</label>
      <v-text-field
        v-model="$parent.pageFixedSettings.footer.block[0].config[1].value"
        @input="$parent.changeConfig('footer', 0)"
        autocomplete="new-password"
        hide-details=""
        id="id"
      ></v-text-field>
    </div>
    <div class="form-component mt-2">
      <label for="">CoppyRight</label>
      <v-textarea
        v-model="$parent.pageFixedSettings.footer.block[0].config[2].value"
        @input="$parent.changeConfig('footer', 0)"
        autocomplete="new-password"
        hide-details=""
        id="id"
      ></v-textarea>
    </div>
    <div v-if="$parent.pageFixedSettings.footer.block[0].config[4]" class="form-component mt-2">
      <label for="">Sub CoppyRight</label>
      <v-text-field
        v-model="$parent.pageFixedSettings.footer.block[0].config[4].value"
        @input="$parent.changeConfig('footer', 0)"
        autocomplete="new-password"
        hide-details=""
        id="id"
      ></v-text-field>
    </div>
    <div class="form-component mt-7">
      <div class="d-flex justify-space-between">
        <label for="">Main Menu</label>
        <span class="pointer link-add" @click="$router.push({ name: 'website.menus' })">Edit Menu</span>
      </div>
      <v-select
        hide-details=""
        v-model="$parent.pageFixedSettings.footer.block[1].config[0].value"
        :items="$parent.mainMenu"
        @input="$parent.changeConfig('menuFooter', 0)"
        item-text="title"
        item-value="id"
      ></v-select>
    </div>
    <v-row v-if="$parent.pageFixedSettings.footer.block[2]">
      <v-col cols="12">
        <h4>CONTENT</h4>
      </v-col>
      <v-expansion-panels accordion class="list-section" style="max-height:1100px !important">
        <draggable v-model="$parent.pageFixedSettings.footer.block[2].config" @change="change" style="width: 100%;">
          <transition-group>
            <v-expansion-panel v-for="(item, i) in $parent.pageFixedSettings.footer.block[2].config" :key="`item${i}`">
              <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
                <i class="fas fa-bars mr-2" style="flex:none !important"></i>

                {{ 'Payment Icon' }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <!-- {{ item.content }} -->
                <UploadOneImage :itemSetData="item" :image="{ src: item.value }" @callback="getImage" />
                <div class="mt-2">
                  <span class="pointer font-weight-bold red--text" @click="removeContent(i)">
                    Remove icon
                  </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </transition-group>
        </draggable>
      </v-expansion-panels>
      <v-col v-if="$parent.pageFixedSettings.footer.block[2]" cols="12">
        <span class="pointer font-weight-bold primary--text" @click="addContent">+ Add Image Payment</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  methods: {
    getImageDeskTop(image) {
      this.$parent.pageFixedSettings.footer.block[0].config[3].value = image.src;
      this.$parent.changeConfig('menu', 0);
    },
    getImage(images, item) {
      item.value = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    removeContent(i) {
      this.$parent.pageFixedSettings.footer.block[2].config.splice(i, 1);
      this.$parent.changeConfig();
    },
    change() {
      this.$parent.changeConfig();
    },
    addContent() {
      this.$parent.pageFixedSettings.footer.block[2].config.push({
        label: 'Icon 1',
        driver: 'select',
        default: null,
        value: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
