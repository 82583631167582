<template>
  <v-row>
    <v-col cols="12"><h3>SETTING</h3> </v-col>
    <v-col cols="12">
      <h4>FLASH DEALS</h4>
    </v-col>
    <v-col cols="12">
      Heading
      <v-text-field v-model="config.settings.content.heading"></v-text-field>
    </v-col>
    <v-expansion-panels accordion class="list-section" style="max-height:1100px !important">
      <draggable
        v-model="config.settings.content.blocks"
        @change="change"
        style="width: 100%;"
        :options="{ disabled: true }"
      >
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config.settings.content.blocks" :key="`item${i}`">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
              {{ item.type === 'collection' ? 'Collection' : 'Count Down' }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <!-- {{ item.content }} -->
              <UploadOneImage
                v-if="item.type === 'count-down'"
                :itemSetData="item"
                :image="{ src: item.image }"
                @callback="getImage"
              />
              <label v-if="item.type === 'count-down'" class="mt-5 d-inline-block" for="">Title</label>
              <v-text-field
                v-if="item.type === 'count-down'"
                height="auto"
                v-model="item.title"
                @input="$parent.changeConfig"
              ></v-text-field>

              <label class="mt-22 d-inline-block" for="">Button link </label>
              <select-link :isCategory="true" :dataUrl="item.url" :itemSetData="item" @route="getRoute" />
              <label v-if="item.type === 'count-down'" class="mt-22 d-inline-block" for="">Time left </label>

              <v-row v-if="item.type === 'count-down'" class="form-component">
                <v-col cols="4">
                  <label for="">Day</label>
                  <v-text-field
                    height="auto"
                    type="number"
                    v-model.number="item.time.day"
                    @input="setTimme(item)"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <label for=""> Hour</label>
                  <v-text-field
                    height="auto"
                    type="number"
                    v-model.number="item.time.hour"
                    @input="setTimme(item)"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <label for="">Minute</label>
                  <v-text-field
                    height="auto"
                    type="number"
                    v-model.number="item.time.min"
                    @input="setTimme(item)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- {{ item }} -->
              <label
                v-if="item.type === 'collection' && item.url && item.url.params && item.url.params.slug"
                class="mt-22 d-inline-block"
                for=""
                >Product
              </label>
              <v-combobox
                v-if="item.type === 'collection' && item.url && item.url.params && item.url.params.slug"
                clearable
                v-model="item.product"
                :items="products"
                @update:search-input="searchProduct($event, item)"
                item-text="name"
                item-value="value"
              ></v-combobox>
              <div>
                <span
                  class="pointer font-weight-bold red--text"
                  @click="callbackRemoveContent(configSection.settings.content.blocks, i)"
                >
                  Remove content
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12" class="d-flex">
      <span
        class="pointer font-weight-bold primary--text"
        @click="callbackAddContent(configSection.settings.content.blocks, `collection_and_product`)"
        >+Add Collection</span
      >
      <v-spacer />
      <span
        class="pointer font-weight-bold primary--text"
        @click="callbackAddContent(configSection.settings.content.blocks, `count_down`)"
        >+Add CountDown</span
      >
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4>FLASH DEALS DETAIL</h4>
    </v-col>
    <v-col cols="12"> </v-col>
    <v-expansion-panels accordion class="list-section" style="max-height:1100px !important">
      <draggable v-model="config.settings.detail.blocks" @change="change" style="width: 100%;">
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config.settings.detail.blocks" :key="`item${i}`">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
              <i class="fas fa-bars mr-2" style="flex:none !important"></i>{{ 'Collection' }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <!-- {{ item.content }} -->
              <UploadOneImage :itemSetData="item" :image="{ src: item.image }" @callback="getImage" />
              <label class="mt-5 d-inline-block" for="">Title</label>
              <v-text-field height="auto" v-model="item.title" @input="$parent.changeConfig"></v-text-field>

              <label class="mt-22 d-inline-block" for="">Button link </label>
              <select-link :isCategory="true" :dataUrl="item.url" :itemSetData="item" @route="getRoute" />
              <div>
                <span
                  class="pointer font-weight-bold red--text"
                  @click="callbackRemoveContent(configSection.settings.detail.blocks, i)"
                >
                  Remove content
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12">
      <span
        v-if="configSection.settings.detail.blocks.length < 10"
        class="pointer font-weight-bold primary--text"
        @click="callbackAddContent(configSection.settings.detail.blocks, `collection_list`)"
        >+ Add Collection</span
      >
    </v-col>
    <v-col cols="12">
      <v-btn small outlined @click="callbackRemoveSection(configSection)"> Remove Section</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import SelectLink from '../driver-theme64/SelectLink.vue';
import { productApi } from '@/apis/product';

export default {
  components: {
    draggable,
    SelectLink,
  },
  name: 'image_with_text',
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
      products: [],
      executor: null,
    };
  },
  methods: {
    setTimme(item) {
      item.time.getTime =
        new Date().getTime() + (item.time.day * 24 * 60 * 60 + item.time.hour * 60 * 60 + item.time.min * 60) * 1000;
      console.log(item, 'time');
    },
    async loadData(e, item) {
      this.products = [];
      try {
        if (e && item?.url?.params?.slug) {
          let res = await productApi.get({ chandle: item?.url?.params?.slug, search: e ? e.trim() : '' });

          // let res = await collectionApi.get({ search: e ? e : '' });
          this.products = res.data.products.map(item => {
            return {
              name: item.title,
              value: item._id,
            };
          });

          this.$forceUpdate();
        }
      } catch (error) {
        console.log(error);
      }
    },
    searchProduct(e, item) {
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }
      this.executor = setTimeout(async () => {
        if (e) {
          await this.loadData(e, item);
        }
      }, 300);
    },
    getRoute(nameRoute, item) {
      item.url = nameRoute;
      this.$parent.changeConfig();
    },
    getImage(images, item) {
      item.image = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    removeContent(i) {},
    change() {
      this.$parent.changeConfig();
    },
  },
  created() {
    this.config = this.configSection;
  },
};
</script>

<style lang="scss" scoped></style>
