<template>
  <v-row v-if="config.settings">
    <v-col cols="12"><h3>SETTING</h3> </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4>CONTENT</h4>
    </v-col>
    <!-- <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.settings.show_sale_banners"
          label="Show sale banners"
        ></v-checkbox>
      </div>
    </v-col> -->
    <v-col cols="12">
      <div class="">
        Heading
        <v-text-field
          hide-details=""
          height="auto"
          v-model="config.settings.heading"
          @input="$parent.changeConfig"
        ></v-text-field>
      </div>
    </v-col>

    <v-col cols="12">
      <div>
        <label class="fz-12">Collections</label>
        <v-combobox
          clearable
          v-model="config.settings.collection"
          hide-details=""
          :items="collectionList"
          item-text="title"
          @update:search-input="searchCollection"
          @change="$parent.changeConfig"
          :return-object="true"
        >
          <!-- <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" small label>
              {{ data.item.title }}
            </v-chip>
          </template> -->
          <template v-slot:item="data">
            <!-- <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template> -->
            <template>
              <v-list-item-content class="d-flex">
                <v-list-item-title>
                  <v-avatar tile size="20" style="">
                    <img
                      :src="data.item.image ? imageHelpers.url(data.item.image.src) : '@/assets/images/no-image.png'"
                      alt=""
                    />
                  </v-avatar>

                  {{ data.item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-combobox>
      </div>
    </v-col>

    <!-- <v-col cols="12">
      <label class="mt-2 d-inline-block" for="">Limit product</label>
      {{ config.settings.limit_product }}
      <v-slider
        v-model="config.settings.limit_product"
        hide-details=""
        class="pointer"
        max="18"
        min="6"
        @input="$parent.changeConfig"
      ></v-slider>
    </v-col> -->
    <v-col cols="12">
      <div class="mt-5">
        Button label
        <v-text-field height="auto" v-model="config.settings.buttonLabel" @input="$parent.changeConfig"></v-text-field>
      </div>
    </v-col>

    <v-col cols="12">
      <v-btn small outlined @click="callbackRemoveSection(configSection)"> Remove Section</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { collectionApi } from '@/apis/collection';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
export default {
  components: {},
  name: 'image_with_text',
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      imageHelpers,
      imageFake,
      config: {},
      collectionList: [],
    };
  },
  methods: {
    searchCollection(e) {
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }
      this.executor = setTimeout(async () => {
        await this.getDataCollection(e);
      }, 300);
    },
    async getDataCollection(e) {
      try {
        let res = await collectionApi.get({ search: e ? e : '' });
        this.collectionList = res.data.collections;
      } catch (error) {
        console.log('error', error);
        //empty
      }
    },
    getRoute(nameRoute, item) {
      item.url = nameRoute;
      this.$parent.changeConfig();
    },
    getImage(images, item) {
      item.image = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    removeContent(i) {},
    change() {
      this.$parent.changeConfig();
    },
  },
  async created() {
    await this.getDataCollection();
    this.config = this.configSection;
  },
};
</script>

<style lang="scss" scoped></style>
