<template>
  <v-row>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Product photos</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.enable_image_zoom"
          label="Enable image zoom"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Product rating</h4></v-col>
    <v-col cols="12">
      <div>
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.productRating"
          label="Show product rating"
          class="option-checkbox-height"
        />
      </div>
    </v-col>
    <v-col cols="12">
      <div>
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.hideReviewSizes"
          label="Hide review sizes"
          class="option-checkbox-height"
        />
      </div>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Color Rating</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <color-picker v-model="config.colorProductRating"></color-picker>
    </v-col>
    <v-col cols="12" class="px-5">
      <h4 class="">Color Buy X get Y</h4>
    </v-col>
    <v-col cols="12" class="pt-0">
      <color-picker v-model="config.colorByXGetY"></color-picker>
    </v-col>
    <!-- 
    <v-col cols="12" class="pt-0">
      <color-picker v-model="config.colorByXGetY"></color-picker>
    </v-col> -->
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Images product mobile</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.isShowImageMobile"
          label="Show image"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Product stock</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_item_stock"
          label="Show items left in stock"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Product collection</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_collection"
          label="Show collection"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Product tags</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.tags.show_tags"
          label="Show tags"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Variant buttons</h4></v-col>
    <v-col col="12">
      <v-text-field type="number" v-model="config.layout.variant_buttons" label="Variant buttons" />
    </v-col>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">Variant description</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_description_variant"
          label="Show variant description"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase">Layout</h4>
    </v-col>
    <v-col cols="12" class="py-5">
      <div class="">
        <v-checkbox
          class="option-checkbox-height"
          @change="$parent.changeConfig"
          v-model="config.show_label_variant"
          label="Show label variant"
        ></v-checkbox>
      </div>
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.layout.show_price_saving"
          label="Show price savings"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
      <div class="">
        <v-checkbox
          class="option-checkbox-height"
          @change="$parent.changeConfig"
          v-model="config.layout.special_layout_on_mobile"
          label="Enable special layout on mobile devices for products with custom options"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="pb-0">
      <label for="" class="font-weight-bold h4">Sale style</label>
      <v-select
        color
        @change="$parent.changeConfig"
        v-model="config.layout.sale_type"
        item-text="name"
        item-value="id"
        class="select-text"
        :items="[
          { name: 'Amount', id: 'amount' },
          { name: 'Percentage', id: 'percent' },
        ]"
      ></v-select>
    </v-col>
    <v-col cols="12" class="pt-0">
      <label for="" class="font-weight-bold h4">Variant style</label>
      <v-select
        color
        @change="$parent.changeConfig"
        v-model="config.layout.variant_style"
        item-text="name"
        item-value="id"
        class="select-text"
        :items="[
          { name: 'Button', id: 'button' },
          { name: 'Dropdown', id: 'dropdown' },
        ]"
      ></v-select>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_qty.show_qty_box"
          label="Show quantity box"
          class="option-checkbox-height"
        ></v-checkbox>
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_qty.show_qty_one_line"
          label="Show quantity box and Add to cart button"
          class="option-checkbox-height"
        ></v-checkbox>
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_qty.show_qty_same_line_button"
          label="Show quantity box in the same line with Add to cart button / Buy now button"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="pb-0">
      <label for="" class="font-weight-bold h4">Description position</label>
      <v-select
        color
        @change="$parent.changeConfig"
        v-model="config['position_tab_detail']"
        item-text="name"
        item-value="id"
        class="select-text"
        :items="[
          { name: 'Description in the right', id: 'right' },
          { name: 'Description below product form', id: 'below' },
        ]"
      ></v-select>
    </v-col>
    <v-col cols="12" class="py-0">
      <label for="" class="font-weight-bold h4">Product title position</label>
      <v-checkbox
        @change="$parent.changeConfig"
        v-model="config.layout.show_title_on_top_mobile"
        label="Show product title on top on mobile"
        class="option-checkbox-height"
      ></v-checkbox>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="py-4">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.options_setting.hidden_one_value"
          label="Hide selector when option has one value"
          class="option-checkbox-height"
        ></v-checkbox>
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.options_setting.click_select_variant"
          label="Select variants by clicking their images"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
      <div>
        <p class="mb-0">Each image must be attached to a variant for this option to work.</p>
      </div>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="pb-0">
      <h4>Tabs</h4>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          v-model="config.isShowDesMobile"
          @input="$parent.changeConfig"
          label="Show description on mobile"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          v-model="config.isShowDesDesktop"
          @input="$parent.changeConfig"
          label="Show description on desktop"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>

    <!-- <v-col cols="12" class="py-4">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.auto_open_tabs_detail"
          label="Auto open tabs detail"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col> -->
    <v-expansion-panels accordion class="list-section" style="max-height: 1100px !important">
      <draggable v-model="config['tabs-detail']" @change="change" style="width: 100%">
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config['tabs-detail']" :key="`item${i}`">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
              <i class="fas fa-bars mr-2" style="flex: none !important"></i>

              {{ item.heading }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <label class="mt-5 d-inline-block" for="">Heading</label>
              <v-text-field v-model="item.heading" height="auto" @input="$parent.changeConfig"></v-text-field>
              <!-- <label class="mt-2 d-inline-block" for="">Content</label> -->
              <EditorCustom
                :content.sync="item.content"
                @change="changeContent"
                v-if="item.display === 'html'"
                height="auto"
              ></EditorCustom>
              <div class="">
                <v-checkbox
                  @change="$parent.changeConfig"
                  v-model="item.collapse_default"
                  label="Collapse this tab by default"
                  class="option-checkbox-height"
                ></v-checkbox>
              </div>
              <div class="my-3">
                <span
                  v-if="item.display !== 'description'"
                  class="pointer font-weight-bold red--text"
                  @click="removeContent(i)"
                >
                  Delete tab
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12" v-if="config['tabs-detail'].length < maxBlock">
      <span class="pointer font-weight-bold primary--text" @click="addContent">+ Add tabs</span>
    </v-col>
    <v-col cols="12" class="d-flex max-block" v-else>
      <v-icon small color="grey lighten-1">
        mdi-plus-box
      </v-icon>
      <div class="pl-4">
        <p class="pointer font-weight-bold primary--text  mb-0">
          {{ config['tabs-detail'].length }}/{{ maxBlock }} blocks used.
        </p>
        <p class="mb-0">Delete to add more.</p>
      </div>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase">policy shipping</h4>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.setting_shipping.show_shipping"
          label="Show policy shipping"
          class="option-checkbox-height-policy"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <EditorCustom
        :content.sync="config.setting_shipping.shipping_info.content"
        :title="config.setting_shipping.title"
        @change="changeContent"
        v-if="config.setting_shipping.shipping_info.display === 'html'"
        height="auto"
      ></EditorCustom>
    </v-col>
    <v-col cols="12"><h4 class="text-uppercase">Range shipping</h4></v-col>
    <v-col cols="12" class="d-felx align-center justify-center">
      <label class="mt-2 d-inline-block" for="">From</label>
      <v-slider
        v-model.number="config.setting_shipping.time_shipping.from"
        class="pointer"
        max="100"
        min="0"
        @input="$parent.changeConfig"
      >
        <template v-slot:append>
          <p>{{ config.setting_shipping.time_shipping.from }} days</p>
        </template>
      </v-slider>
    </v-col>
    <v-col cols="12" class="d-felx align-center justify-center">
      <label class="mt-2 d-inline-block" for="">To</label>
      <v-slider
        v-model.number="config.setting_shipping.time_shipping.to"
        class="pointer"
        max="100"
        min="0"
        @input="$parent.changeConfig"
      >
        <template v-slot:append>
          <p>{{ config.setting_shipping.time_shipping.to }} days</p>
        </template>
      </v-slider>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase">trust badge</h4>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.trust_badge.show_trust_badge_image"
          label="Show trust badge image"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
      <div class="pb-6">
        <UploadOneImage :image="{ src: config.trust_badge.src.value }" @callback="getImageTrustBadge" />
      </div>
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase">Setting ATC/BuyNow</h4>
    </v-col>
    <v-col cols="12">
      <v-radio-group v-model="config.checkoutStepLayout">
        <v-radio :label="`To CartPage`" :value="1"></v-radio>
        <v-radio :label="`To Checkout Page`" :value="2"></v-radio>
        <v-radio :label="`Open mini Cart Page `" :value="3"></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import EditorCustom from './EditorCustom';
import settingDataDefault from '../../settings-data.json';
import ColorPicker from './ColorPicker.vue';
export default {
  components: {
    draggable,
    EditorCustom,
    ColorPicker,
  },
  name: 'product-setting',
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
      data: settingDataDefault,
      maxBlock: 5,
    };
  },
  methods: {
    changeContent() {
      this.$parent.changeConfig();
    },
    removeContent(i) {
      this.$parent.settings['product']['tabs-detail'].splice(i, 1);
      this.$parent.changeConfig();
    },
    addContent() {
      this.$parent.settings['product']['tabs-detail'].push({
        heading: 'Tab new',
        id: Math.random(),
        type: 'custom',
        display: 'html',
        content: '',
      });
      this.$parent.changeConfig();
    },
    getRoute(nameRoute, item) {
      item.url_button = nameRoute;
      this.$parent.changeConfig();
    },
    getImage(images, item) {
      item.image = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    change() {
      this.$parent.changeConfig();
    },
    getImageTrustBadge(image) {
      this.config.trust_badge.src.value = image.src;
      this.$parent.changeConfig();
    },
  },
  created() {
    this.config = this.configSection;
    if (this.config.setting_shipping && !this.config.setting_shipping.time_shipping) {
      this.config.setting_shipping.time_shipping = this.data.settings.product.setting_shipping.time_shipping;
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  font-size: 14px;
}
.option-checkbox-height {
  height: 40px;
}
.v-input--selection-controls {
  padding: 0px;
  margin: 0px;
}
.max-block {
  background-color: #f8f8fc;
}
</style>
