<template>
  <v-row>
    <!-- <div style="margin-top: 15px"> -->
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase px-0">Change slides every</h4>
      <v-slider
        label=""
        v-model="config.settings.change_slides_every"
        class="align-center"
        :max="12"
        :min="1"
        thumb-label
      >
        <template v-slot:append>
          <p>{{ config.settings.change_slides_every }}sec</p>
        </template>
      </v-slider>
    </v-col>
    <v-col cols="12" class="pb-0">
      <h4 class="text-uppercase px-0">Opacity</h4>
      <v-slider label="" v-model="config.settings.opacity_image" class="align-center" :max="100" :min="0" thumb-label>
        <template v-slot:append>
          <p>{{ config.settings.opacity_image }}%</p>
        </template>
      </v-slider>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.settings.show_content"
          label="Show heading, subtitle and button."
          class="option-checkbox-height-policy"
        ></v-checkbox>
      </div>
    </v-col>
    <v-divider class="my-2"></v-divider>
    <v-col cols="12">
      <h4 class="text-uppercase px-0">Content</h4>
    </v-col>
    <v-divider class="my-2"></v-divider>
    <!-- slides desktop -->
    <v-col cols="12">
      <div class="text-uppercase px-0 subtitle-2">Desktop</div>
    </v-col>
    <v-col cols="12" class="px-0">
      <v-expansion-panels accordion class="list-section" style="max-height: 1100px !important">
        <draggable v-model="config.settings.content.desktop" @change="change" style="width: 100%">
          <transition-group>
            <v-expansion-panel v-for="(item, i) in config.settings.content.desktop" :key="`item${i}-desktop`">
              <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading" @click="openSlide(i)">
                <i class="fas fa-bars mr-2" style="flex: none !important"></i>
                Slideshow
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- <label class="mt-5 d-inline-block" for="">Content</label> -->
                <div class="form-component banner mb-5">
                  <label>Image</label>
                  <UploadOneImage :itemSetData="item" :image="{ src: item.image }" @callback="getImage" />
                </div>
                <v-divider class="my-2"></v-divider>
                <div class="form-component banner mb-5">
                  <label>Media</label>
                  <UploadOneImage
                    :itemSetData="item"
                    :image="{ src: item.media }"
                    @callback="getMedia"
                    :editable="true"
                  />
                </div>
                <v-divider class="my-2"></v-divider>
                <v-col cols="12 px-0">
                  <h3 class="text-uppercase px-0">Title</h3>
                </v-col>
                <div class="form-component">
                  <label for="">Content</label>
                  <v-text-field
                    v-model="item.heading.content"
                    @input="$parent.changeConfig('banner', 0)"
                    autocomplete="new-password"
                    height="auto"
                    id="id"
                  ></v-text-field>
                </div>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Color</h4>
                </v-col>
                <v-col cols="12" class="pa-0 banner-setting-color px-3 py-2 mb-4">
                  <div class="form-component mb-3">
                    <div class="mb-5">
                      <!-- <label for="" class="label-setting">Desktop</label> -->
                      <ColorPicker v-model="item.heading.color"></ColorPicker>
                    </div>
                    <!-- <div class="mb-2">
                      <label for="" class="label-setting">Mobile</label>
                      <ColorPicker v-model="item.heading.colorMobile"></ColorPicker>
                    </div> -->
                  </div>
                </v-col>
                <v-divider class="my-2"></v-divider>
                <v-col cols="12 px-0">
                  <h3 class="text-uppercase px-0">Sub Title</h3>
                </v-col>
                <div class="form-component">
                  <label for="">Content</label>
                  <v-textarea
                    v-model="item.subHeading.content"
                    @input="$parent.changeConfig('banner', 0)"
                    autocomplete="new-password"
                    id="id"
                  ></v-textarea>
                </div>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Color</h4>
                </v-col>
                <v-col cols="12" class="pa-0 banner-setting-color px-3 py-2 mb-4">
                  <div class="form-component mb-3">
                    <div class="mb-5">
                      <!-- <label for="" class="label-setting">Desktop</label> -->
                      <ColorPicker v-model="item.subHeading.color"></ColorPicker>
                    </div>
                    <!-- <div class="mb-2">
                      <label for="" class="label-setting">Mobile</label>
                      <ColorPicker v-model="item.subHeading.colorMobile"></ColorPicker>
                    </div> -->
                  </div>
                </v-col>
                <v-divider class="my-2"></v-divider>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Alignment</h4>
                </v-col>
                <v-col cols="12" class="py-0 px-0">
                  <v-btn-toggle
                    v-model="item.position"
                    tile
                    class="w-100 btn-position-toggle"
                    color="teal darken-4"
                    group
                  >
                    <v-btn value="left" elevation="0" class="btn-position w-100 col-4" width="200px">
                      Left
                    </v-btn>
                    <v-btn value="center" elevation="0" class="btn-position w-100 col-4">
                      Center
                    </v-btn>
                    <v-btn value="right" elevation="0" class="btn-position w-100 col-4">
                      Right
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-divider class="my-2"></v-divider>
                <div class="form-component mt-2">
                  <label for="">Button</label>
                  <v-text-field
                    v-model="item.buttonLabel.title"
                    @input="$parent.changeConfig('banner', 0)"
                    autocomplete="new-password"
                    id="id"
                  ></v-text-field>
                  <label class="mt-22 d-inline-block" for="">Button link </label>
                  <SelectLink
                    :isAllCollection="
                      storeFrontDefaultTheme &&
                      storeFrontDefaultTheme.storeFrontDefaultTheme &&
                      storeFrontDefaultTheme.storeFrontDefaultTheme === 'newchick'
                        ? false
                        : true
                    "
                    :dataUrl="item.urlButton"
                    :itemSetData="item"
                    @route="getRoute"
                  />
                </div>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Color</h4>
                </v-col>
                <v-col cols="12" class="pa-0 banner-setting-color px-3 py-2 mb-4">
                  <div class="form-component mb-3">
                    <div class="mb-5">
                      <!-- <label for="" class="label-setting">Desktop</label> -->
                      <ColorPicker v-model="item.buttonLabel.color"></ColorPicker>
                    </div>
                    <!-- <div class="mb-2">
                      <label for="" class="label-setting">Mobile</label>
                      <ColorPicker v-model="item.buttonLabel.color.mobile"></ColorPicker>
                    </div> -->
                  </div>
                  <!-- <pre> {{ item.buttonLabel }}</pre> -->
                </v-col>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Background color</h4>
                </v-col>
                <v-col cols="12" class="pa-0 banner-setting-color px-3 py-2 mb-4">
                  <div class="form-component mb-3">
                    <div class="mb-5">
                      <!-- <label for="" class="label-setting"> Desktop</label> -->
                      <ColorPicker v-model="item.buttonLabel.background"></ColorPicker>
                    </div>
                    <!-- <div class="mb-2">
                      <label for="" class="label-setting">Mobile</label>
                      <ColorPicker v-model="item.buttonLabel.background.mobile"></ColorPicker>
                    </div> -->
                  </div>
                </v-col>
                <v-col cols="12 px-0">
                  <span class="pointer font-weight-bold primary--text" @click="removeContent(i, 'desktop')">
                    Remove slideshow
                  </span>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </transition-group>
        </draggable>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12">
      <span class="pointer font-weight-bold primary--text" @click="addContent('desktop')">+ Add slideshow</span>
    </v-col>
    <!-- mobile -->
    <v-divider class="my-2"></v-divider>
    <v-divider />
    <v-col cols="12">
      <div class="text-uppercase px-0 subtitle-2">Mobile</div>
    </v-col>
    <v-col cols="12" class="px-0">
      <v-expansion-panels accordion class="list-section" style="max-height: 1100px !important">
        <draggable v-model="config.settings.content.mobile" @change="change" style="width: 100%">
          <transition-group>
            <v-expansion-panel v-for="(item, i) in config['settings']['content']['mobile']" :key="`item${i}-mobile`">
              <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading" @click="openSlide(i)">
                <i class="fas fa-bars mr-2" style="flex: none !important"></i>
                Slideshow
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- <label class="mt-5 d-inline-block" for="">Content</label> -->
                <div class="form-component banner mb-5">
                  <label>Image</label>
                  <UploadOneImage :itemSetData="item" :image="{ src: item.image }" @callback="getImage" />
                </div>
                <v-divider class="my-2"></v-divider>
                <div class="form-component banner mb-5">
                  <label>Media</label>
                  <UploadOneImage
                    :itemSetData="item"
                    :image="{ src: item.media }"
                    @callback="getMedia"
                    :editable="true"
                  />
                </div>
                <v-divider class="my-2"></v-divider>
                <v-col cols="12 px-0">
                  <h3 class="text-uppercase px-0">Title</h3>
                </v-col>
                <div class="form-component">
                  <label for="">Content</label>
                  <v-text-field
                    v-model="item.heading.content"
                    @input="$parent.changeConfig('banner', 0)"
                    autocomplete="new-password"
                    height="auto"
                    id="id"
                  ></v-text-field>
                </div>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Color</h4>
                </v-col>
                <v-col cols="12" class="pa-0 banner-setting-color px-3 py-2 mb-4">
                  <div class="form-component mb-3">
                    <div class="mb-5">
                      <!-- <label for="" class="label-setting">Desktop</label> -->
                      <ColorPicker v-model="item.heading.color"></ColorPicker>
                    </div>
                    <!-- <div class="mb-2">
                      <label for="" class="label-setting">Mobile</label>
                      <ColorPicker v-model="item.heading.colorMobile"></ColorPicker>
                    </div> -->
                  </div>
                </v-col>
                <v-divider class="my-2"></v-divider>
                <v-col cols="12 px-0">
                  <h3 class="text-uppercase px-0">Sub Title</h3>
                </v-col>
                <div class="form-component">
                  <label for="">Content</label>
                  <v-textarea
                    v-model="item.subHeading.content"
                    @input="$parent.changeConfig('banner', 0)"
                    autocomplete="new-password"
                    id="id"
                  ></v-textarea>
                </div>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Color</h4>
                </v-col>
                <v-col cols="12" class="pa-0 banner-setting-color px-3 py-2 mb-4">
                  <div class="form-component mb-3">
                    <div class="mb-5">
                      <!-- <label for="" class="label-setting">Desktop</label> -->
                      <ColorPicker v-model="item.subHeading.color"></ColorPicker>
                    </div>
                    <!-- <div class="mb-2">
                      <label for="" class="label-setting">Mobile</label>
                      <ColorPicker v-model="item.subHeading.colorMobile"></ColorPicker>
                    </div> -->
                  </div>
                </v-col>
                <v-divider class="my-2"></v-divider>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Alignment</h4>
                </v-col>
                <v-col cols="12" class="py-0 px-0">
                  <v-btn-toggle
                    v-model="item.position"
                    tile
                    class="w-100 btn-position-toggle"
                    color="teal darken-4"
                    group
                  >
                    <v-btn value="left" elevation="0" class="btn-position w-100 col-4" width="200px">
                      Left
                    </v-btn>
                    <v-btn value="center" elevation="0" class="btn-position w-100 col-4">
                      Center
                    </v-btn>
                    <v-btn value="right" elevation="0" class="btn-position w-100 col-4">
                      Right
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-divider class="my-2"></v-divider>
                <div class="form-component mt-2">
                  <label for="">Button</label>
                  <v-text-field
                    v-model="item.buttonLabel.title"
                    @input="$parent.changeConfig('banner', 0)"
                    autocomplete="new-password"
                    id="id"
                  ></v-text-field>
                  <label class="mt-22 d-inline-block" for="">Button link </label>
                  <SelectLink
                    :isAllCollection="
                      storeFrontDefaultTheme &&
                      storeFrontDefaultTheme.storeFrontDefaultTheme &&
                      storeFrontDefaultTheme.storeFrontDefaultTheme === 'newchick'
                        ? false
                        : true
                    "
                    :dataUrl="item.urlButton"
                    :itemSetData="item"
                    @route="getRoute"
                  />
                </div>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Color</h4>
                </v-col>
                <v-col cols="12" class="pa-0 banner-setting-color px-3 py-2 mb-4">
                  <div class="form-component mb-3">
                    <div class="mb-5">
                      <!-- <label for="" class="label-setting">Desktop</label> -->
                      <ColorPicker v-model="item.buttonLabel.color"></ColorPicker>
                    </div>
                    <!-- <div class="mb-2">
                      <label for="" class="label-setting">Mobile</label>
                      <ColorPicker v-model="item.buttonLabel.color.mobile"></ColorPicker>
                    </div> -->
                  </div>
                  <!-- <pre> {{ item.buttonLabel }}</pre> -->
                </v-col>
                <v-col cols="12 px-0">
                  <h4 class="text-uppercase px-0">Background color</h4>
                </v-col>
                <v-col cols="12" class="pa-0 banner-setting-color px-3 py-2 mb-4">
                  <div class="form-component mb-3">
                    <div class="mb-5">
                      <!-- <label for="" class="label-setting"> Desktop</label> -->
                      <ColorPicker v-model="item.buttonLabel.background"></ColorPicker>
                    </div>
                    <!-- <div class="mb-2">
                      <label for="" class="label-setting">Mobile</label>
                      <ColorPicker v-model="item.buttonLabel.background.mobile"></ColorPicker>
                    </div> -->
                  </div>
                </v-col>
                <v-col cols="12 px-0">
                  <span class="pointer font-weight-bold primary--text" @click="removeContent(i, 'mobile')">
                    Remove slideshow
                  </span>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </transition-group>
        </draggable>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12">
      <span class="pointer font-weight-bold primary--text" @click="addContent('mobile')">+ Add slideshow</span>
    </v-col>
    <!--- slide item  --->
    <v-col cols="12">
      <v-btn small outlined @click="callbackRemoveSection(configSection)"> Remove Section</v-btn>
    </v-col>
    <!-- </div> -->
  </v-row>
</template>

<script>
import SelectLink from './SelectLink';
import draggable from 'vuedraggable';
import ColorPicker from './ColorPicker';
export default {
  name: 'head-image',
  components: {
    SelectLink,
    draggable,
    ColorPicker,
  },
  computed: {
    storeFrontDefaultTheme() {
      return this.$store.state.pagePreference.preference;
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    },
  },
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    config: {
      handler() {
        this.$parent.changeConfig('', this.index, 'headImage');
      },
      deep: true,
    },
    'config.settings.change_slides_every': function() {
      this.$parent.changeConfig('', null, 'changeSlide');
    },
    'config.settings.opacity_image': function() {
      this.$parent.changeConfig('', null, 'opacityImage');
    },
  },
  data() {
    return {
      config: {},
      index: 0,
      slides: [
        {
          image:
            'https://minio.lattehub.com/img/0/0/resize/60d9a7f8454744683569f1e1/2021/08/20/lattehub-image-611f5c964fe89a9efa26b416.jpg',
          heading: {
            content: 'Walk the walk.',
            colorDesktop: '#FFFFFF',
            colorMobile: '#000000',
          },
          subHeading: {
            content:
              'A fashion can become the prevailing style in behaviour or manifest the newest creations of designers, technologists, engineers, and design managers.',
            colorDesktop: '#FFFFFF',
            colorMobile: '#000000',
          },
          buttonLabel: {
            title: 'Shop Now Test',
            color: {
              desktop: '#FFFFFF',
              mobile: '#000000',
            },
            background: {
              desktop: '#FFFFFF',
              mobile: '#F29E0D',
            },
          },
          urlButton: {
            name: 'home',
          },
          position: 'center',
        },
      ],
      slidelist: [
        {
          image:
            'https://minio.lattehub.com/img/0/0/resize/60d9a7f8454744683569f1e1/2021/08/20/lattehub-image-611f5c964fe89a9efa26b416.jpg',
          heading: {
            content: 'Walk the walk.',
            color: '#FFFFFF',
          },
          subHeading: {
            content:
              'A fashion can become the prevailing style in behaviour or manifest the newest creations of designers, technologists, engineers, and design managers.',
            color: '#FFFFFF',
          },
          buttonLabel: {
            title: 'Shop Now Test',
            color: '#FFFFFF',
            background: '#FFFFFF',
          },
          urlButton: {
            name: 'home',
          },
          position: 'center',
        },
      ],
    };
  },
  methods: {
    getRoute(nameRoute, item) {
      item.urlButton = nameRoute;
      this.$parent.changeConfig();
    },
    getImage(image, item) {
      item.image = image.src;
      this.$parent.changeConfig();
    },
    getMedia(image, item) {
      item.media = image.src;
      this.$parent.changeConfig();
    },
    removeContent(i, device) {
      if (device === 'desktop') {
        this.config.settings.content.desktop.splice(i, 1);
      } else if (device === 'mobile') {
        this.config.settings.content.mobile.splice(i, 1);
      }
      this.$parent.changeConfig();
    },
    addContent(device) {
      console.log('tesst');
      let obj = {
        image:
          'https://minio.lattehub.com/img/0/0/resize/60d9a7f8454744683569f1e1/2021/08/20/lattehub-image-611f5c964fe89a9efa26b416.jpg',
        heading: {
          content: 'Walk the walk.',
          color: '#FFFFFF',
        },
        subHeading: {
          content:
            'A fashion can become the prevailing style in behaviour or manifest the newest creations of designers, technologists, engineers, and design managers.',
          color: '#FFFFFF',
        },
        buttonLabel: {
          title: 'Shop Now Test',
          color: '#FFFFFF',
          background: '#FFFFFF',
        },
        urlButton: {
          name: 'home',
        },
        position: 'center',
      };
      if (device === 'desktop') {
        this.config.settings.content.desktop.push(obj);
      } else if (device === 'mobile') {
        this.config.settings.content.mobile.push(obj);
      }
      this.$parent.changeConfig();
    },
    change() {
      // this.$parent.changeConfig('', null, 'changeSlide');
    },
    openSlide(i) {
      this.index = i;
      this.$parent.changeConfig('', i, 'headImage');
    },
    // getImageUpLoad(obj) {
    //   this.config.settings.images.push(obj);
    //   this.$parent.changeConfig();
    // },
    // getDeleteCallback(key) {
    //   if (this.$route.params.id) {
    //     this.config.deleteImageIds.push(this.config.settings.images[key]._id);
    //   }
    //   this.config.settings.images.splice(key, 1);
    //   this.$parent.changeConfig();
    // },
  },
  created() {
    // if (!this.configSection.settings.slides) {
    //   this.configSection.settings.slides = this.slides;
    // }
    if (!this.configSection.settings.opacity_image) {
      this.configSection.settings.opacity_image = 50;
    }
    if (!this.configSection.settings.change_slides_every) {
      this.configSection.settings.change_slides_every = 4;
    }
    if (!this.configSection.settings.content) {
      this.configSection.settings.content = {
        mobile: [
          {
            image:
              'https://minio.lattehub.com/img/0/0/resize/60d9a7f8454744683569f1e1/2021/08/20/lattehub-image-611f5c964fe89a9efa26b416.jpg',
            heading: {
              content: 'Walk the walk.',
              color: '#FFFFFF',
            },
            subHeading: {
              content:
                'A fashion can become the prevailing style in behaviour or manifest the newest creations of designers, technologists, engineers, and design managers.',
              color: '#FFFFFF',
            },
            buttonLabel: {
              title: 'Shop Now Test',
              color: '#FFFFFF',
              background: '#FFFFFF',
            },
            urlButton: {
              name: 'home',
            },
            position: 'center',
          },
        ],
        desktop: [
          {
            image:
              'https://minio.lattehub.com/img/0/0/resize/60d9a7f8454744683569f1e1/2021/08/20/lattehub-image-611f5c964fe89a9efa26b416.jpg',
            heading: {
              content: 'Walk the walk.',
              color: '#FFFFFF',
            },
            subHeading: {
              content:
                'A fashion can become the prevailing style in behaviour or manifest the newest creations of designers, technologists, engineers, and design managers.',
              color: '#FFFFFF',
            },
            buttonLabel: {
              title: 'Shop Now Test',
              color: '#FFFFFF',
              background: '#FFFFFF',
            },
            urlButton: {
              name: 'home',
            },
            position: 'center',
          },
        ],
      };
    }
    this.config = this.configSection;
    this.$parent.changeConfig();
  },
};
</script>

<style lang="scss" scoped>
.label-setting {
  font-size: 13px;
}
.banner-setting-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
  // border-radius: 5px;
}
.btn-position-toggle {
  // border: 1px solid rgba(0, 0, 0, 0.12);
  height: 40px !important;
}
.btn-position {
  min-width: 60px !important;
  height: 40px !important;
  margin: 0px !important;
  padding: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.v-item--active {
  // background-color: #a98a57!important;
}
</style>
