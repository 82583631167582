<template>
  <div style="margin-top: 15px" class="pa-3">
    <!-- <input type="text" v-model="image" />
              <button @click="above(1)">Doi anh</button> -->
    <div class="form-component">
      <label for="">Show TopBar</label>
      <v-checkbox
        v-model="$parent.pageFixedSettings.header.settings.show_top_bar"
        @click="$parent.changeConfig('header', 0)"
      ></v-checkbox>
      <v-textarea
        v-model="$parent.pageFixedSettings.header.settings.text_topbar"
        autocomplete="new-password"
        @input="$parent.changeConfig('header', 0)"
        id="id"
      />
    </div>
    <div class="form-component">
      <label for="">Show Account </label>
      <v-checkbox
        v-model="$parent.pageFixedSettings.header.settings.isShowAccount"
        @click="$parent.changeConfig('header', 0)"
      ></v-checkbox>
    </div>
    <v-divider class="my-3"></v-divider>
    <div class="form-component mt-2">
      <label for="">Image Topbar (only on Desktop)</label>
      <UploadOneImage
        :image="{ src: $parent.pageFixedSettings.header.settings.image_topbar }"
        @callback="getImageTopbar"
      />
    </div>
    <div class="form-component mt-2">
      <label for="">Image Topbar (only on Mobile)</label>
      <UploadOneImage
        :image="{ src: $parent.pageFixedSettings.header.settings.image_topbar_mobile }"
        @callback="getImageTopbarMobile"
      />
    </div>
    <div class="form-component mt-2">
      <label for="">Link topbar</label>
      <select-link
        :dataUrl="$parent.pageFixedSettings.header.settings.topbar_url"
        :itemSetData="$parent.pageFixedSettings.header.settings.topbar_url"
        @route="getRoute"
      />
    </div>
    <v-divider class="my-3"></v-divider>
    <div class="form-component mt-2">
      <label for="">Image DeskTop</label>
      <UploadOneImage
        :image="{ src: $parent.pageFixedSettings.header.settings.logo_desktop }"
        @callback="getImageDeskTop"
      />
    </div>
    <v-col cols="12">
      <div class="">
        Height
        <v-text-field
          hide-details=""
          height="auto"
          v-model="$parent.pageFixedSettings.header.settings.size_logo.desktop"
          @input="$parent.changeConfig('header', 0)"
        ></v-text-field>
      </div>
    </v-col>
    <v-divider class="my-3"></v-divider>
    <div class="form-component mt-7">
      <label for="">Image Mobile</label>
      <UploadOneImage
        :image="{ src: $parent.pageFixedSettings.header.settings.logo_mobile }"
        @callback="getImageMobile"
      />
    </div>
    <v-col cols="12">
      <div class="">
        Height
        <v-text-field
          hide-details=""
          height="auto"
          v-model="$parent.pageFixedSettings.header.settings.size_logo.mobile"
          @input="$parent.changeConfig('header', 0)"
        ></v-text-field>
      </div>
    </v-col>
    <!-- <div class="sreach-mobile form-component mt-7">
      <label for="">Setting search in mobile</label>
      <v-radio-group v-model="radioGroup">
        <v-radio
          v-for="n in 3"
          :key="n"
          :label="`Radio ${n}`"
          :value="n"
        ></v-radio>
      </v-radio-group>
    </div> -->
    <div class="form-component mt-7">
      <div class="d-flex justify-space-between">
        <label for="">Main Menu</label>
        <span class="pointer link-add" @click="$router.push({ name: 'website.menus' })">Edit Menu</span>
      </div>
      <v-select
        v-model="$parent.pageFixedSettings.header.settings.main_menu"
        :items="$parent.mainMenu"
        @input="$parent.changeConfig('menu', 0)"
        item-text="title"
        item-value="id"
      ></v-select>
    </div>
    <!-- <v-divider class="my-3"></v-divider> -->
  </div>
</template>

<script>
import SelectLink from '../driver-theme64/SelectLink.vue';
export default {
  components: { SelectLink },
  methods: {
    getRoute(nameRoute, item) {
      this.$parent.pageFixedSettings.header.settings.topbar_url = nameRoute;
      this.$parent.changeConfig();
    },
    getImageMobile(image) {
      this.$parent.pageFixedSettings.header.settings.logo_mobile = image.src;
      this.$parent.changeConfig('menu', 0);
    },
    getImageTopbar(image) {
      this.$parent.pageFixedSettings.header.settings.image_topbar = image.src;
      this.$parent.changeConfig('menu', 0);
    },
    getImageTopbarMobile(image) {
      this.$parent.pageFixedSettings.header.settings.image_topbar_mobile = image.src;
      this.$parent.changeConfig('menu', 0);
    },
    getImageDeskTop(image) {
      this.$parent.pageFixedSettings.header.settings.logo_desktop = image.src;
      this.$parent.changeConfig('menu', 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
