<template>
  <v-sheet class="overflow-hidden font-drawer" style="">
    <v-row class="pa-3">
      <v-col cols="12" class="py-2" :class="fontWeight">
        {{ $parent.config[model].font }}
      </v-col>
      <v-col cols="12" class="text-capitalize py-2">
        {{ $parent.config[model].style }}
      </v-col>
    </v-row>
    <v-container class="fill-height">
      <v-row align="start" justify="center">
        <v-btn depressed @click.stop="drawer = !drawer" class="col-12"> Change </v-btn>
      </v-row>
    </v-container>
    <v-navigation-drawer v-model="drawer" absolute temporary width="330">
      <v-list-item>
        <v-row align="center" justify="center">
          <v-col cols="11" align="center" class="font-weight-bold">Select Font</v-col>
          <v-icon @click.stop="drawer = !drawer">mdi-close</v-icon>
        </v-row>
      </v-list-item>
      <v-divider></v-divider>
      <!-- <v-list-item>
        <v-row align="center" justify="center">
          <v-col cols="12" align="center" class="font-weight-bold">
            <v-text-field
              dense
              placeholder="Search"
              color="grey darken-2"
              outlined
              v-model="search"
              @input="searchFont"
              class="font-weight-regular"
              prepend-inner-icon="fas fa-search"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-list-item> -->
      <v-list dense>
        <v-list-item
          v-for="item in fonts"
          :key="item.family"
          link
          :class="active === item.family ? 'active-list-font' : ''"
          @click="changeFontFamily(item)"
        >
          <v-list-item-content>
            <v-list-item-title class="fs-20" :style="{ 'font-family': item.family }">
              {{ item.family }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item class="setting-font-weight">
        <v-divider></v-divider>
        <v-row align="center" justify="center">
          <v-col cols="12" align="left" :class="fontWeight" :style="{ 'font-family': $parent.config[model].font }">
            {{ $parent.config[model].font }}
          </v-col>
          <v-col>
            <v-select
              :return-object="true"
              @change="changeStyle"
              color
              item-text="style"
              class="select-text"
              :items="styles"
            ></v-select>
          </v-col>
        </v-row>
      </v-list-item>
    </v-navigation-drawer>
  </v-sheet>
</template>
<script>
export default {
  props: {
    listFont: {
      type: Array,
      default: () => [],
    },
    model: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fontFamily: {},
      drawer: null,
      items: this.font,
      styles: [],
      fonts: [],
      search: '',
      active: '',
    };
  },
  computed: {
    fontWeight() {
      return '';
      // return 'font-weight-' + this.$parent.config[model].style;
    },
  },
  watch: {
    listFont: {
      handler: function() {
        this.fonts = this.listFont;
      },
      deep: true,
    },
  },
  created() {
    this.fonts = this.listFont;
  },
  methods: {
    convertWeightName(value) {
      switch (value) {
        case `100`:
          return 'Thin';
        // code block
        case `200`:
          return 'Extra Light ';
        case `300`:
          return 'Light';
        // code block
        case `400`:
          return 'Regular';
        case `500`:
          return 'Medium';
        // code block
        case `600`:
          return 'Semi Bold';
        case `700`:
          return 'Bold';
        // code block
        case `800`:
          return 'Extra Bold';
        case `900`:
          return 'Black';
        // code block
        case `950`:
          return 'Extra Black';
        case `100italic`:
          return 'Thin Italic';
        // code block
        case `200italic`:
          return 'Extra Light Italic';
        case `300italic`:
          return 'Light Italic';
        // code block
        case `400italic`:
          return 'Regular Italic';
        case `regular`:
          return 'Regular';
        case `italic`:
          return 'Italic';
        case `500italic`:
          return 'Medium Italic';
        // code block
        case `600italic`:
          return 'Semi Bold Italic';
        case `700italic`:
          return 'Bold Italic';
        // code block
        case `800italic`:
          return 'Extra Bold Italic';
        case `900italic`:
          return 'Black Italic';
        // code block
        case `950italic`:
          return 'Extra Black Italic';
        default:
        // code block
      }
    },
    changeStyle(e) {
      this.$parent.config[this.model].style = e.style;
      this.$parent.config[this.model].url = e.url;
      this.$parent.config[this.model].key = e.key;
      console.log(e, this.$parent.config);
    },
    changeFontFamily(fontFamily) {
      this.active = fontFamily.family;
      this.$parent.config[this.model].style = '';
      this.$parent.config[this.model].url = null;
      this.$parent.config[this.model].key = '';
      this.styles = [];
      if (fontFamily) {
        this.$parent.config[this.model].font = fontFamily.family;
        for (let i = 0; i < Object.keys(fontFamily.files).length; i++) {
          let obj = {};
          obj = {
            style: this.convertWeightName(Object.keys(fontFamily.files)[i]),
            key: Object.keys(fontFamily.files)[i],
            url: fontFamily.files[Object.keys(fontFamily.files)[i]],
          };
          this.styles.push(obj);
        }
      }
    },
    searchFont() {
      this.fonts = this.items.filter(x => x.title.toLowerCase().search(this.search.toLowerCase()) === 0);
    },
  },
};
</script>
<style lang="scss">
.fs-18 {
  font-size: 18px;
}
.setting-font-weight {
  position: fixed;
  bottom: 0px;
}
.font-drawer {
  .active-list-font {
    background: #dddddd;
  }
}
</style>
