<template>
  <v-row v-if="config.settings">
    <v-col cols="12">
      <div class="mt-5">
        Heading

        <v-text-field height="auto" v-model="config.settings.heading" @input="$parent.changeConfig"></v-text-field>
      </div>
    </v-col>
    <v-col cols="12">
      <h4>Collection 1</h4>
    </v-col>
    <v-col cols="12">
      <div class="mt-5">
        Heading

        <v-text-field
          height="auto"
          v-model="config.settings.collectionStart.heading"
          @input="$parent.changeConfig"
        ></v-text-field>
      </div>
    </v-col>
    <v-expansion-panels accordion class="list-section" style="max-height:1100px !important">
      <draggable v-model="config.settings.collectionStart.blocks" @change="change" style="width: 100%;">
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config.settings.collectionStart.blocks" :key="`item${i}`">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
              <i class="fas fa-bars mr-2" style="flex:none !important"></i>{{ 'Colection' }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <!-- {{ item.content }} -->
              <UploadOneImage :itemSetData="item" :image="{ src: item.image }" @callback="getImage" />
              <label class="mt-5 d-inline-block" for="">Title</label>
              <v-text-field height="auto" v-model="item.title" @input="$parent.changeConfig"></v-text-field>

              <label class="mt-22 d-inline-block" for="">Button link </label>
              <SelectLink :isCategory="true" :dataUrl="item.url" :itemSetData="item" @route="getRoute" />
              <div>
                <span
                  class="pointer font-weight-bold red--text"
                  @click="callbackRemoveContent(configSection.settings.collectionStart.blocks, i)"
                >
                  Remove content
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12">
      <span
        class="pointer font-weight-bold primary--text"
        @click="callbackAddContent(configSection.settings.collectionStart.blocks, configSection.type)"
        >+ Add Collection</span
      >
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4>Featured Collection</h4>
    </v-col>
    <v-col cols="12">
      <div class="mt-5">
        Heading

        <v-text-field
          height="auto"
          v-model="config.settings.featured_collection.heading"
          @input="$parent.changeConfig"
        ></v-text-field>
      </div>
    </v-col>

    <v-col cols="12">
      <div>
        <label class="fz-12">Collections</label>
        <v-combobox
          clearable
          v-model="config.settings.featured_collection.collection"
          hide-details=""
          :items="collectionList"
          item-text="title"
          @update:search-input="searchCollection"
          @change="$parent.changeConfig"
          :return-object="true"
        >
          <!-- <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" small label>
              {{ data.item.title }}
            </v-chip>
          </template> -->
          <template v-slot:item="data">
            <!-- <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template> -->
            <template>
              <v-list-item-content class="d-flex">
                <v-list-item-title>
                  <v-avatar tile size="20" style="">
                    <img
                      :src="data.item.image ? imageHelpers.url(data.item.image.src) : '@/assets/images/no-image.png'"
                      alt=""
                    />
                  </v-avatar>

                  {{ data.item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-combobox>
      </div>
    </v-col>

    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4>Collection 2</h4>
    </v-col>
    <v-col cols="12">
      <div class="mt-5">
        Heading

        <v-text-field
          height="auto"
          v-model="config.settings.collectionEnd.heading"
          @input="$parent.changeConfig"
        ></v-text-field>
      </div>
    </v-col>
    <v-expansion-panels accordion class="list-section" style="max-height:1100px !important">
      <draggable v-model="config.settings.collectionEnd.blocks" @change="change" style="width: 100%;">
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config.settings.collectionEnd.blocks" :key="`item${i}`">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
              <i class="fas fa-bars mr-2" style="flex:none !important"></i>{{ 'Colection' }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <!-- {{ item.content }} -->
              <UploadOneImage :itemSetData="item" :image="{ src: item.image }" @callback="getImage" />
              <label class="mt-5 d-inline-block" for="">Title</label>
              <v-text-field height="auto" v-model="item.title" @input="$parent.changeConfig"></v-text-field>

              <label class="mt-22 d-inline-block" for="">Button link </label>
              <select-link :isCategory="true" :dataUrl="item.url" :itemSetData="item" @route="getRoute" />
              <div>
                <span
                  class="pointer font-weight-bold red--text"
                  @click="callbackRemoveContent(configSection.settings.collectionEnd.blocks, i)"
                >
                  Remove content
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12">
      <span
        class="pointer font-weight-bold primary--text"
        @click="callbackAddContent(configSection.settings.collectionEnd.blocks, configSection.type)"
        >+ Add Collection</span
      >
    </v-col>

    <v-col cols="12">
      <v-btn small outlined @click="callbackRemoveSection(configSection)"> Remove Section</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { collectionApi } from '@/apis/collection';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import draggable from 'vuedraggable';
import SelectLink from '../driver-theme64/SelectLink.vue';
export default {
  name: 'image_with_text',
  components: { SelectLink, draggable },
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      imageHelpers,
      imageFake,
      collectionList: [],
      config: {},
    };
  },
  methods: {
    searchCollection(e) {
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }
      this.executor = setTimeout(async () => {
        await this.getDataCollection(e);
      }, 300);
    },
    async getDataCollection(e) {
      try {
        let res = await collectionApi.get({ search: e ? e : '' });
        this.collectionList = res.data.collections;
      } catch (error) {
        console.log('error', error);
        //empty
      }
    },
    getRoute(nameRoute, item) {
      item.url = nameRoute;
      this.$parent.changeConfig();
    },
    getImage(images, item) {
      item.image = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    removeContent(i) {},
    change() {
      this.$parent.changeConfig();
    },
  },
  async created() {
    await this.getDataCollection();
    this.config = this.configSection;
    console.log('configSection', this.configSection);
  },
};
</script>

<style lang="scss" scoped></style>
