<template>
  <v-row>
    <v-col cols="12"><h3>SETTING</h3> </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4>Custom HTML</h4>
    </v-col>
    <v-col cols="12">
      <v-textarea @input="$parent.changeConfig" v-model="config.settings.body_html" placeholder=""></v-textarea>
    </v-col>
    <v-col cols="12">
      <v-btn small outlined @click="callbackRemoveSection(configSection)"> Remove Section</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'product-review',
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
    };
  },
  methods: {
    getImage(images, item) {
      item.image = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    removeContent(i) {},
    change() {
      this.$parent.changeConfig();
    },
  },
  created() {
    this.config = this.configSection;
  },
};
</script>

<style lang="scss" scoped></style>
