<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <!-- <h4 class="text-uppercase">Title product list in mobile</h4> -->
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.enabled_currency"
          label="Enable currency conversion"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'currency-setting',
  props: {
    configSection: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
    };
  },
  created() {
    this.config = this.configSection;
  },
};
</script>
