<template>
  <v-row>
    <v-col cols="12"><h3>SETTING</h3> </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4>CONTENT</h4>
    </v-col>
    <v-col cols="12">
      <div class="mt-5">
        Heading

        <v-text-field height="auto" v-model="config.settings.heading" @input="$parent.changeConfig"></v-text-field>
      </div>
    </v-col>
    <v-expansion-panels accordion class="list-section" style="max-height:1100px !important">
      <draggable v-model="config.settings.block" @change="change" style="width: 100%;">
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config.settings.block" :key="`item${i}`">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
              <i class="fas fa-bars mr-2" style="flex:none !important"></i>{{ 'Colection' }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <!-- {{ item.content }} -->
              <UploadOneImage :itemSetData="item" :image="{ src: item.image }" @callback="getImage" />
              <label class="mt-22 d-inline-block" for="">Button link </label>
              <select-link :isCategory="true" :dataUrl="item.url" :itemSetData="item" @route="getRoute" />
              <!-- <div>
                <span
                  class="pointer font-weight-bold red--text"
                  @click="callbackRemoveContent(configSection.settings.blocks, i)"
                >
                  Remove content
                </span>
              </div> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <!-- <v-col cols="12">
      <span
        class="pointer font-weight-bold primary--text"
        @click="callbackAddContent(configSection.settings.blocks, configSection.type)"
        >+ Add Collection</span
      >
    </v-col> -->
    <v-col cols="12">
      <v-btn small outlined @click="callbackRemoveSection(configSection)"> Remove Section</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import SelectLink from '../driver-theme64/SelectLink.vue';
export default {
  components: {
    draggable,
    SelectLink,
  },
  name: 'image_with_text',
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
    };
  },
  methods: {
    getRoute(nameRoute, item) {
      item.url = nameRoute;
      this.$parent.changeConfig();
    },
    getImage(images, item) {
      item.image = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    removeContent(i) {},
    change() {
      this.$parent.changeConfig();
    },
  },
  created() {
    this.config = this.configSection;
  },
};
</script>

<style lang="scss" scoped></style>
