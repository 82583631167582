<template>
  <div
    @mouseleave="mouseleave"
    @mouseover="mouseOver"
    :class="'section item-section d-flex ' + (isShowAddSection ? 'section-active' : '')"
    style="height:40px"
    @click="callbackSelection(type, dataSection)"
  >
    <i class="fal fa-credit-card-blank"></i>
    <div class="ml-3">
      <div class="fz-16">{{ convertTitle.charAt(0).toUpperCase() + convertTitle.slice(1) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataSection: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    position: {
      type: Number,
      default: 0,
    },
    subTitle: {
      type: String,
      default: '',
    },
    section: {
      type: String,
      default: 'header',
    },
    isAddSection: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => {},
    },
    callbackAddSection: {
      type: Function,
      default: () => {},
    },
    callbackSelection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isShowAddSection: false,
      convertTitle: '',
    };
  },
  methods: {
    mouseleave() {
      this.isShowAddSection = false;
    },
    mouseOver() {
      this.isShowAddSection = true;
    },
  },
  created() {
    this.convertTitle = this.title
      .replace('-', ' ')
      .replace('_', ' ')
      .replace('_', ' ');
  },
};
</script>

<style lang="scss" scoped>
.item-section {
  position: relative;
  // display: flex;
  .add-s {
    background: #dbdfe1 !important;
    z-index: 10;
    border-radius: 2px;
  }
  .section-above {
    position: absolute;
    font-size: 12px;
    right: 40%;
    top: -9px;
  }
  .section-below {
    position: absolute;
    font-size: 12px;
    right: 40%;
    bottom: -9px;
  }
}
.section-active {
  background: white !important;
  box-shadow: 0px 0px 0px 3px rgb(181 189 194 / 16%), 0px 16px 40px 0px rgb(54 62 67 / 16%);
  z-index: 1;
}
</style>
