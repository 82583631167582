<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h4 class="text-uppercase">Font Heading Text</h4>
      </v-col>
    </v-row>
    <v-row class=" px-4">
      <v-col cols="12 typography-bd">
        <FontFamily v-if="!isLoadFont" model="type_header_family" :listFont="listFont" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4 class="text-uppercase">Font Body Text</h4>
      </v-col>
    </v-row>
    <v-row class=" px-4">
      <v-col cols="12 typography-bd">
        <FontFamily v-if="!isLoadFont" model="type_base_family" :listFont="listFont" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4 class="text-uppercase">Product Description Text</h4>
      </v-col>
    </v-row>
    <v-row class=" px-4">
      <v-col cols="12 typography-bd">
        <FontFamily v-if="!isLoadFont" model="type_product_description_family" :listFont="listFont" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4 class="text-uppercase">Font Button Text</h4>
      </v-col>
    </v-row>
    <v-row class=" px-4">
      <v-col cols="12 typography-bd">
        <FontFamily v-if="!isLoadFont" model="type_button_base_family" :listFont="listFont" />
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="12">
        <h4 class="text-uppercase">Font Base</h4>
      </v-col>
    </v-row>
    <v-row class=" px-4">
      <v-col cols="12 typography-bd">
        <FontFamily model="type_base_family" :listFont="listFont" />
      </v-col>
    </v-row> -->
    <v-row class="mt-4">
      <v-col cols="12" class="py-1">
        <h4 class="text-uppercase">Font size In product page</h4>
      </v-col>
      <v-col cols="12">
        <span>Title product</span>
        <v-slider label="" v-model="config.font_size_product" class="align-center" :max="80" :min="8" thumb-label>
          <template v-slot:append>
            <p>{{ config.font_size_product }}px</p>
          </template>
        </v-slider>
      </v-col>

      <v-col cols="12">
        <span>Price</span>
        <v-slider v-model="config.font_size_price" class="align-center" :max="80" :min="8" thumb-label>
          <template v-slot:append>
            <p>{{ config.font_size_price }}px</p>
          </template>
        </v-slider>
      </v-col>

      <v-col cols="12">
        <span>Compare at price</span>
        <v-slider v-model="config.font_size_compare_at_price" class="align-center" :max="80" :min="8" thumb-label>
          <template v-slot:append>
            <p>{{ config.font_size_compare_at_price }}px</p>
          </template>
        </v-slider>
      </v-col>

      <v-col cols="12">
        <span>Sale tag</span>
        <v-slider v-model="config.font_size_sale_tag" class="align-center" :max="80" :min="8" thumb-label>
          <template v-slot:append>
            <p>{{ config.font_size_sale_tag }}px</p>
          </template>
        </v-slider>
      </v-col>

      <v-col cols="12">
        <span>Cart button text</span>
        <v-slider v-model="config.font_size_cart_button_text" class="align-center" :max="80" :min="8" thumb-label>
          <template v-slot:append>
            <p>{{ config.font_size_cart_button_text }}px</p>
          </template>
        </v-slider>
      </v-col>
      <!-- <v-col cols="12" class="py-0">
        <div class="">
          <v-checkbox
            label="Capitalize Headings"
            class="option-checkbox-height"
            @change="$parent.changeConfig"
            v-model="config.capitalize_headings"
          ></v-checkbox>
        </div>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import FontFamily from './FontFaminy';
import Api from '@/apis/';
import $ from 'jquery';
import axios from 'axios';
export default {
  name: 'typography',
  data() {
    return {
      isLoadFont: false,
      config: {},
      fontDefault: [
        'Alata',
        'Mulish',
        'Poppins',
        'Open Sans',
        'Abril Fatface',
        'Quicksand',
        'Aclonica',
        'Quintessential',
        'Radley',
        'Rancho',
        'Rokkitt',
        'Share',
        'Sevillana',
        'Assistant',
        'Roboto',
      ],
      listFont: [],
    };
  },
  components: {
    FontFamily,
  },
  props: {
    configSection: {
      type: Object,
      default: () => {},
    },
  },
  async created() {
    this.config = this.configSection;
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        // let res = await commonApi.getOutside(
        //   `https://fonts.googleapis.com/css?family=Arial|Mulish|Poppins|Open Sans|Abril Fatface`,
        // );
        //https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyA3ojYhGFCJnCL8Q45gkEGjXfPd3tBMUAQ
        let api = new Api();
        this.isLoadFont = true;
        // let res = await api.get(
        //   `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyA3ojYhGFCJnCL8Q45gkEGjXfPd3tBMUAQ`,
        // );
        axios
          .get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyA3ojYhGFCJnCL8Q45gkEGjXfPd3tBMUAQ')
          .then(({ data }) => {
            this.listFont = data.items.filter(item => this.fontDefault.includes(item.family));
            // this.listFont = response.data.items;
          })
          .catch(error => {
            console.log('error', error);
          });
        // this.listFont = res.data.items.filter(item => this.fontDefault.includes(item.family));
        let getFONT = await api.get(`https://fonts.googleapis.com/css?family=${this.fontDefault.join('|')}`);
        $('head').append(`<style type='text/css'>${getFONT.data}</style>`);
        // console.log('res', res);
      } catch (error) {}
      this.isLoadFont = false;
    },
  },
};
</script>
<style>
.typography-bd {
  border: 1px solid #eee;
}
</style>
