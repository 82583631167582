<template>
  <v-text-field :value="value" v-model="color" @change="change" hide-details class="ma-0 pa-0" solo>
    <template v-slot:append class="color-picker">
      <v-menu top nudge-bottom="0" nudge-left="0" :close-on-click="true" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle(value)" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker @input="getColor" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '#ffffff',
    },
  },
  data() {
    return {
      color: null,
    };
  },
  methods: {
    swatchStyle(color) {
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '22px',
        width: '22px',
        borderRadius: '24px',
        transition: 'border-radius 200ms ease-in-out',
      };
    },
    getColor(event) {
      this.color = event.hex;
      this.$emit('input', event.hex);
    },
    change() {
      this.$emit('input', this.color);
    },
  },
  created() {
    this.color = this.value;
  },
};
</script>
<style lang="scss">
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 30px;
}
</style>
