<template>
  <v-row v-if="config" class="editor-page">
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">DISCOUNT POPUP</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          hide-details=""
          @change="$parent.changeConfig"
          v-model="config.discount.isShow"
          label="Show discount popup"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="form-component">
        <label for="">Image</label>
        <UploadOneImage :image="{ src: config.discount.image }" @callback="getImage" />
      </div>
    </v-col>
    <v-col cols="12">
      <editor-new :dataT.sync="config.discount.title" :title="`Title`" />
    </v-col>
    <v-col cols="12">
      <editor-new :dataT.sync="config.discount.subTitle" :title="`Sub Title`" />
    </v-col>
    <v-col cols="12">
      <editor-new :dataT.sync="config.discount.content" :title="`Content`" />
    </v-col>
    <v-col cols="12" class="form-component">
      <label class="mt-1 d-inline-block" for="">Placeholder</label>
      <v-text-field
        hide-details=""
        height="auto"
        v-model="config.discount.placeholder"
        @input="$parent.changeConfig"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="form-component">
      <label class="mt-1 d-inline-block" for="">Button</label>
      <v-text-field
        hide-details=""
        height="auto"
        v-model="config.discount.button"
        @input="$parent.changeConfig"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">WELCOME POPUP</h4></v-col>
    <!-- <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.welcome.isShow"
          label="Show Welcome popup"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col> -->
    <v-col cols="12">
      <editor-new :dataT.sync="config.welcome.title" :title="`Title`" />
    </v-col>
    <v-col cols="12">
      <editor-new :dataT.sync="config.welcome.content" :title="`Content`" />
    </v-col>
    <v-col cols="12" class="form-component">
      <label class="mt-1 d-inline-block" for="">Code</label>
      <v-text-field
        hide-details=""
        height="auto"
        v-model="config.welcome.code"
        @input="$parent.changeConfig"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <editor-new :dataT.sync="config.welcome.successTitle" :title="`Success Title`" />
    </v-col>

    <v-col cols="12" class="form-component">
      <label class="mt-1 d-inline-block" for="">Button</label>
      <v-text-field
        hide-details=""
        height="auto"
        v-model="config.welcome.button"
        @input="$parent.changeConfig"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="py-0"><h4 class="text-uppercase">SUBSCRIBE POPUP</h4></v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.subscribe.isShow"
          label="Show subscribe popup"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <editor-new :dataT.sync="config.subscribe.title" :title="`Title`" />
    </v-col>
    <v-col cols="12">
      <editor-new :dataT.sync="config.subscribe.content" :title="`Content`" />
    </v-col>

    <v-col cols="12" class="form-component">
      <label class="mt-1 d-inline-block" for="">Button</label>
      <v-text-field
        hide-details=""
        height="auto"
        v-model="config.subscribe.button"
        @input="$parent.changeConfig"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import settingDataDefault from '../../settings-data.json';
import EditorCustom from '../driver-theme64/EditorCustom.vue';
import { pageService } from '@/apis/page.s';
import EditorNew from '../EditorNew.vue';
export default {
  components: {
    // draggable,
    EditorNew,
  },
  name: 'product-setting',
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      pages: [],
      config: {},
      data: settingDataDefault,
      maxBlock: 5,
    };
  },
  methods: {
    getImage(image) {
      this.config.discount.image = image.src;
      this.$parent.changeConfig();
    },
    async getPage() {
      try {
        let res = await pageService.list();
        this.pages = res.data.pages.filter(it => it.published);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    changeContent() {
      this.$parent.changeConfig();
    },
    removeContent(i) {
      this.$parent.settings['product']['tabs-detail'].splice(i, 1);
      this.$parent.changeConfig();
    },
    addContent() {
      this.$parent.settings['product']['tabs-detail'].push({
        heading: 'Tab new',
        id: Math.random(),
        type: 'custom',
        display: 'html',
        content: '',
      });
      this.$parent.changeConfig();
    },
    getRoute(nameRoute, item) {
      item.url_button = nameRoute;
      this.$parent.changeConfig();
    },
    // getImage(images, item) {
    //   item.image = images.src;
    //   this.$parent.changeConfig();
    //   //   this.attributes.image = images;
    // },
    change() {
      this.$parent.changeConfig();
    },
    // getImageTrustBadge(image) {
    //   this.config.trust_badge.src.value = image.src;
    //   this.$parent.changeConfig();
    // },
  },
  created() {
    console.log('this.config', this.config);
    this.config = this.configSection;
    // this.getPage();
  },
};
</script>

<style lang="scss" scoped>
* {
  font-size: 14px;
}
.option-checkbox-height {
  height: 40px;
}
.v-input--selection-controls {
  padding: 0px;
  margin: 0px;
}
.max-block {
  background-color: #f8f8fc;
}
.editor-page {
}
</style>
