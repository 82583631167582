<template>
  <v-row>
    <v-col cols="12"><h3>SETTING</h3> </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.steps.settings.isShowPage"
          label="Enable order custom steps at Thank You Page"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <h4>Steps</h4>
    </v-col>
    <v-expansion-panels accordion class="list-section" style="max-height: 1100px !important">
      <draggable v-model="config['steps']['settings']['blocks']" @change="change" style="width: 100%">
        <transition-group>
          <v-expansion-panel
            :readonly="!item.isEdit"
            v-for="(item, i) in config['steps']['settings']['blocks']"
            :key="`item${i}`"
          >
            <v-expansion-panel-header :expand-icon="item.isEdit ? `mdi-menu-down` : ''" class="heading">
              <i v-if="item.isEdit" class="fas fa-bars mr-2" style="flex: none !important"></i>

              {{ item.name }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <label class="mt-5 d-inline-block" for="">Content</label>
              <v-text-field v-model="item.name" height="auto" @input="$parent.changeConfig"></v-text-field>
              <label class="mt-5 d-inline-block" for="">Change after the previous step</label>
              <div class="d-flex">
                <v-text-field class="mr-2" v-model.number="item.time" type="number"></v-text-field>
                <v-select
                  color
                  v-model="item.unit"
                  item-text="name"
                  item-value="id"
                  :items="[
                    { name: 'Day(s)', id: 'days' },
                    { name: 'Hour(s)', id: 'hours' },
                    { name: 'Minute(s)', id: 'minutes' },
                  ]"
                ></v-select>
              </div>
              <div>
                <span
                  v-if="item.display !== 'description'"
                  class="pointer font-weight-bold red--text"
                  @click="removeContent(i, 'steps')"
                >
                  Delete Step
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12" v-if="config['steps']['settings']['blocks'].length < 4">
      <span class="pointer font-weight-bold primary--text" @click="addContent('steps')">+ Add step</span>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config['delivery_policies'].settings.isShowPage"
          label="Enable Delivery Policies at Thank You Page"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <h4>DELIVERY POLICIES</h4>
    </v-col>
    <v-expansion-panels accordion class="list-section" style="max-height: 1100px !important">
      <draggable v-model="config['delivery_policies']['settings']['blocks']" @change="change" style="width: 100%">
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config['delivery_policies']['settings']['blocks']" :key="`item${i}`">
            <v-expansion-panel-header :expand-icon="item.isEdit ? `mdi-menu-down` : ''" class="heading">
              <i v-if="item.isEdit" class="fas fa-bars mr-2" style="flex: none !important"></i>

              {{ item.name }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <label class="mt-5 d-inline-block" for="">Name</label>
              <v-text-field v-model="item.name" height="auto" @input="$parent.changeConfig"></v-text-field>
              <label class="mt-5 d-inline-block" for="">Content</label>
              <v-textarea v-model="item.content" height="auto" @input="$parent.changeConfig"></v-textarea>
              <!-- <label class="mt-2 d-inline-block" for="">Content</label> -->

              <div>
                <span class="pointer font-weight-bold red--text" @click="removeContent(i, 'delivery_policies')">
                  Delete Policy
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12" v-if="config['delivery_policies']['settings']['blocks'].length < 3">
      <span class="pointer font-weight-bold primary--text" @click="addContent('delivery_policies')">+ Add polices</span>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import EditorCustom from './EditorCustom';
export default {
  components: {
    draggable,
  },
  name: 'feature-content',
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
      rules: {
        required: value => !!value || 'Required.',
        number: value => {
          const pattern = /^\d*\.?\d*$/;
          return pattern.test(value) || 'Invalid number.';
        },
      },
    };
  },
  methods: {
    changeContent() {
      this.$parent.changeConfig();
    },
    removeContent(i, type) {
      this.$parent.settings['thankpage'][type]['settings']['blocks'].splice(i, 1);
      this.$parent.changeConfig();
    },
    addContent(type) {
      let obj = {};
      if (type === 'steps') {
        obj = {
          name: 'Custom step',
          isEdit: true,
          unit: 'days',
          time: 1,
        };
        this.$parent.settings['thankpage'][type]['settings']['blocks'].splice(2, 0, obj);
      }
      if (type === 'delivery_policies') {
        obj = {
          name: 'Delivery policy',
          content: '',
        };
        this.$parent.settings['thankpage'][type]['settings']['blocks'].push(obj);
      }
      this.$parent.changeConfig();
    },
    getRoute(nameRoute, item) {
      item.url_button = nameRoute;
      this.$parent.changeConfig();
    },
    getImage(images, item) {
      item.image = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    change() {
      this.$parent.changeConfig();
    },
  },
  created() {
    console.log('configSection', this.configSection);
    this.config = this.configSection;
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel--active + .v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: 1px;
}
</style>
