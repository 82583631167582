<template>
  <v-row>
    <v-col cols="12">
      <h4 class="text-uppercase">trust badge</h4>
    </v-col>
    <v-col cols="12">
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.trust_badge.show_trust_badge_image"
          label="Show trust badge image"
          class="option-checkbox-height"
        ></v-checkbox>
      </div>
      <div class="pb-6">
        <UploadOneImage :image="{ src: config.trust_badge.src.value }" @callback="getImageTrustBadge" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'cart-setting',
  data() {
    return {
      config: {},
    };
  },
  props: {
    configSection: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    changeContent() {
      this.$parent.changeConfig();
    },
    getImageTrustBadge(image) {
      this.config.trust_badge.src.value = image.src;
      this.$parent.changeConfig();
    },
  },
  created() {
    this.config = this.configSection;
  },
};
</script>

<style></style>
