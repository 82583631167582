<template>
  <v-row>
    <v-col cols="12"><h3>SETTING</h3> </v-col>
    <v-col cols="12">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12">
      <h4>CONTENT</h4>
    </v-col>
    <v-expansion-panels accordion class="list-section" style="max-height:1100px !important">
      <draggable v-model="config.settings.blocks" @change="change" style="width: 100%;">
        <transition-group>
          <v-expansion-panel v-for="(item, i) in config.settings.blocks" :key="`item${i}`">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="heading">
              <i class="fas fa-bars mr-2" style="flex:none !important"></i>

              {{ 'Image with text' }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <label class="mt-2 d-inline-block" for="">Position</label>
              <v-btn-toggle
                class="mb-3"
                style="width:100%"
                v-model="item.position"
                @change="$parent.changeConfig()"
                mandatory
              >
                <v-btn small width="50%" :value="'left'"> Left </v-btn>
                <v-btn small width="50%" :value="'right'">Right </v-btn>
              </v-btn-toggle>
              <!-- {{ item.content }} -->
              <UploadOneImage :itemSetData="item" :image="{ src: item.image }" @callback="getImage" />
              <label class="mt-5 d-inline-block" for="">Heading</label>
              <v-text-field height="auto" v-model="item.heading" @input="$parent.changeConfig"></v-text-field>
              <label class="mt-2 d-inline-block" for="">Content</label>
              <v-textarea height="auto" v-model="item.content" @input="$parent.changeConfig"></v-textarea>

              <label class="mt-2 d-inline-block" for="">Button label</label>
              <v-text-field
                class=""
                height="auto"
                v-model="item.button_label"
                @input="$parent.changeConfig"
              ></v-text-field>

              <label class="mt-22 d-inline-block" for="">Button link </label>
              <SelectLink :isAllCollection="true" :dataUrl="item.url_button" :itemSetData="item" @route="getRoute" />
              <div>
                <span
                  class="pointer font-weight-bold red--text"
                  @click="callbackRemoveContent(configSection.settings.blocks, i)"
                >
                  Remove content
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </transition-group>
      </draggable>
    </v-expansion-panels>
    <v-col cols="12">
      <span
        class="pointer font-weight-bold primary--text"
        @click="callbackAddContent(configSection.settings.blocks, configSection.type)"
        >+ Add Image With Text</span
      >
    </v-col>
    <v-col cols="12">
      <v-btn small outlined @click="callbackRemoveSection(configSection)"> Remove Section</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import SelectLink from './SelectLink';
export default {
  components: {
    draggable,
    SelectLink,
  },
  name: 'image_with_text',
  props: {
    callbackRemoveContent: {
      type: Function,
      default: () => {},
    },
    configSection: {
      type: Object,
      default: () => {},
    },
    callbackAddContent: {
      type: Function,
      default: () => {},
    },
    callbackRemoveSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
    };
  },
  methods: {
    getRoute(nameRoute, item) {
      item.url_button = nameRoute;
      this.$parent.changeConfig();
    },
    getImage(images, item) {
      item.image = images.src;
      this.$parent.changeConfig();
      //   this.attributes.image = images;
    },
    removeContent(i) {},
    change() {
      this.$parent.changeConfig();
    },
  },
  created() {
    this.config = this.configSection;
  },
};
</script>

<style lang="scss" scoped></style>
