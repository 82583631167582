let imageFake =
  process.env.NODE_ENV === 'development'
    ? '/601a0d4860ebbe0e58df4c5f/2021/03/11/lattehub-image-60499aec69488a35719d2c4e.png'
    : '/60924e756675a342081d1a8f/2021/05/12/lattehub-image-609b55931062db6e53184092.png';
export const blockDefaultHomePage = {
  flash_deals: {
    settings: {
      content: {
        heading: '',
        blocks: [
          {
            title: 'Count Down',
            type: 'count-down',
            url: {
              name: 'category',
              slug: null,
            },
            image:
              'https://minio.lattex.xyz/img/200/200/resize/6100b9997484b32e3991a795/2021/08/24/lattehub-image-612495a5ce8047cf3af2ba8e.png',
            time: {
              day: 5,
              hour: 0,
              min: 0,
              getTime: 0,
            },
          },
          {
            title: 'Collection title',
            type: 'collection',
            url: {
              name: 'category',
              slug: null,
            },
            product: null,
          },
          {
            title: 'Collection title',
            type: 'collection',
            url: {
              name: 'category',
              slug: null,
            },
            product: null,
          },
          {
            title: 'Collection title',
            type: 'collection',
            url: {
              name: 'category',
              slug: null,
            },
            product: null,
          },
        ],
      },
      detail: {
        blocks: [
          {
            title: 'Collection title',
            url: {
              name: 'category',
              slug: null,
            },
            image: 'https://cdn.btdmp.com/dist/themes/3/3/images/shoes.4b48f7d2.svg',
          },
          {
            title: 'Collection title',
            url: {
              name: 'category',
              slug: null,
            },
            image: 'https://cdn.btdmp.com/dist/themes/3/3/images/shoes.4b48f7d2.svg',
          },
          {
            title: 'Collection title',
            url: {
              name: 'category',
              slug: null,
            },
            image: 'https://cdn.btdmp.com/dist/themes/3/3/images/shoes.4b48f7d2.svg',
          },
        ],
      },
    },
    type: 'flash_deal',
  },
  popup: {
    welcome: {
      isShow: true,
      title: 'Welcome',
      content: '',
      button: 'Shop now',
      code: '',
    },
    subscribe: {
      isShow: true,
      title: 'Subscribe',
      content: ' Subscribe and get 15% off  coupon ',
      button: 'Shop now',
    },
  },
  ['featured-collection']: {
    type: 'featured-collection',
    settings: {
      item_per_row: 4,
      heading: 'Featured Collection',
      collection: null,
      buttonLabel: 'View more',
      isTextLink: false,
      align: 'left',
      limit_product: 18,
    },
  },
  product_with_collection: {
    settings: {
      heading: "What's Hot",
      collectionStart: {
        heading: 'Hot Categories1',
        blocks: [
          {
            url: { name: 'category' },
            image: 'https://cdn.btdmp.com/dist/themes/3/3/images/shoes.4b48f7d2.svg',
          },
        ],
      },
      featured_collection: {
        heading: 'Featured Collection',
        collection: null,
      },
      collectionEnd: {
        heading: 'Hot Categories1',
        blocks: [
          {
            url: { name: 'category' },
            image: 'https://cdn.btdmp.com/dist/themes/3/3/images/shoes.4b48f7d2.svg',
          },
        ],
      },
    },
    type: 'product_with_collection',
    visible: true,
  },
  'style-collection': {
    settings: {
      block: [
        {
          image: 'https://imgaz1.chiccdn.com/os/202107/20210718221048_376.jpg.webp',
          url: { name: 'home' },
        },
        {
          image: 'https://imgaz1.chiccdn.com/os/202107/20210718221156_827.jpg.webp',
          url: { name: 'home' },
        },
        {
          image: 'https://imgaz1.chiccdn.com/os/202107/20210718221233_229.jpg.webp',
          url: { name: 'home' },
        },
        {
          image: 'https://imgaz1.chiccdn.com/os/202107/20210718221233_229.jpg.webp',
          url: { name: 'home' },
        },
        {
          image: 'https://imgaz1.chiccdn.com/os/202107/20210718221356_618.jpg.webp',
          url: { name: 'home' },
        },
      ],
      heading: '2021 Summer Style',
    },
    type: 'style-collection',
    visible: true,
  },
  ['collection_list']: {
    settings: {
      blocks: [
        {
          title: 'Collection title',
          url: { name: 'category' },
          image: 'https://cdn.btdmp.com/dist/themes/3/3/images/shoes.4b48f7d2.svg',
        },
        {
          title: 'Collection title',
          url: { name: 'category' },
          image: 'https://cdn.btdmp.com/dist/themes/3/3/images/watches.ac2e166e.svg',
        },
        {
          title: 'Collection title',
          url: { name: 'category' },
          image: 'https://cdn.btdmp.com/dist/themes/3/3/images/cameras.0971a2f2.svg',
        },
      ],
      collections: [
        {
          id: '1',
        },
      ],
      heading: 'Collection list',
      item_per_row: 3,
      item_per_row_mobile: 2,
    },
    type: 'collection_list',
    visible: true,
  },
  ['image_with_text']: {
    settings: {
      blocks: [
        {
          image: imageFake,
          heading: 'Your headline',
          show_button: false,
          content: 'Promotion description appears here.',
          button_label: 'VIEW COLLECTION',
          url_button: { name: 'home' },
          position: 'left',
        },
        {
          image: imageFake,
          heading: 'Your headline',
          show_button: false,
          content: 'Promotion description appears here.',
          button_label: 'VIEW COLLECTION',
          url_button: { name: 'home' },
          position: 'right',
        },
      ],
      choose_layout: 'image_with_text_above',
      images_per_row: 3,
      promotions_animation: 'fade_in',
      text_alignment: 'left',
    },
    type: 'image_with_text',
    visible: true,
  },
  ['head-image']: {
    settings: {
      order: '1',
      label: 'Banner',
      component: 'head-image',
      image:
        'https://minio.lattehub.com/img/0/0/resize/60d9a7f8454744683569f1e1/2021/08/20/lattehub-image-611f5c964fe89a9efa26b416.jpg',
      heading: 'Walk the walk.',
      subHeading:
        'A fashion can become the prevailing style in behaviour or manifest the newest creations of designers, technologists, engineers, and design managers.',
      buttonLabel: 'Shop Now',
      urlButtonLabel: { name: 'home' },
    },
    change_slides_every: 6,
    text_animation: 'fade_down',
    title: 'Banner',
    type: 'head-image',
    visible: true,
  },
  ['product-review']: {
    type: 'product-review',
    settings: {
      body_html: 'html .....',
    },
  },
  ['feature-content']: {
    type: 'feature-content',
    settings: {
      blocks: [
        {
          image: 'https://img.btdmp.com/files/10110793/2020/09/10/180x180@1599711359f32d671ca3.png',
          heading: 'REMEMBER TO DRINKs',
          content: 'Always remember to drink water with hourly markers on the bottle!',
        },
        {
          image: 'https://img.btdmp.com/files/10110793/2020/09/10/180x180@1599711359f32d671ca3.png',
          heading: 'REMEMBER TO DRINK',
          content: 'Always remember to drink water with hourly markers on the bottle!',
        },
        {
          image: 'https://img.btdmp.com/files/10110793/2020/09/10/180x180@1599711359f32d671ca3.png',
          heading: 'REMEMBER TO DRINK',
          content: 'Always remember to drink water with hourly markers on the bottle!',
        },
      ],
      choose_layout: 'image_with_text_above',
      images_per_row: 3,
      promotions_animation: 'fade_in',
      text_alignment: 'left',
    },
  },
  ['slider-product']: {
    settings: {
      blocks: [
        {
          id: '1',
          heading: 'Heading',
          item_per_row: 5,
        },
      ],
    },
    type: 'slider-product',
    visible: true,
  },
};
export const blockDefaultThankyouPage = {
  ['steps']: {
    settings: {
      isShowPage: true,
      blocks: [
        {
          name: 'Order Placed',
          isEdit: false,
          root: true,
          unit: 'days',
          time: null,
        },
        {
          name: 'Order Processing',
          isEdit: true,
          unit: 'days',
          time: 1,
        },
        {
          name: 'Packaging & Quality Control',
          isEdit: true,
          unit: 'days',
          time: 1,
        },
        {
          name: 'Shipped',
          isEdit: false,
          unit: 'days',
          time: null,
        },
      ],
    },
    type: 'step',
  },
  ['delivery_policies']: {
    settings: {
      isShowPage: true,
      blocks: [
        {
          name: 'Production time',
          content:
            'Your order will be produced within 7-10 days after your order is placed. Please allow us to make your own items with all our best, so we take great pride in delivering the highest quality products for our customers. So much do thank you for your patience.',
        },
        {
          name: 'Shipping time',
          content:
            'Please wait 10-20 business days for your items to be shipped and delivered after their production. Besides, you will receive a tracking number 7-10 days after you place your order. If your tracking number does not show data, please allow up to 48 hours for the information to appear.',
        },
      ],
    },
    type: 'delivery_policies',
  },
};
