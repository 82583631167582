import { render, staticRenderFns } from "./EditHeader.vue?vue&type=template&id=10dc0462&scoped=true&"
import script from "./EditHeader.vue?vue&type=script&lang=js&"
export * from "./EditHeader.vue?vue&type=script&lang=js&"
import style0 from "./EditHeader.vue?vue&type=style&index=0&id=10dc0462&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10dc0462",
  null
  
)

export default component.exports