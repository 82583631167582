<template>
  <v-row>
    <v-col cols="12">
      <h4 class="text-uppercase">Banner</h4>
    </v-col>
    <v-col cols="12">
      <div class="text-subtitle-1 font-weight-bold">Show banner</div>
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_banner"
          label="Show banner"
          class="option-checkbox-height-policy"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase">Title and button mobile</h4>
    </v-col>
    <v-col cols="12">
      <div class="text-subtitle-1 font-weight-bold">Show title and button</div>
      <div class="">
        <v-checkbox
          @change="$parent.changeConfig"
          v-model="config.show_title_button"
          label="Show title and button"
          class="option-checkbox-height-policy"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="text-subtitle-1 font-weight-bold">
        Desktop
      </div>
      <div class="pb-6">
        <UploadOneImage :image="{ src: config.banner_desktop }" @callback="getImageTrustBadgeDesktop" />
      </div>
    </v-col>
    <v-col cols="12">
      <div class="text-subtitle-1 font-weight-bold">
        Mobile
      </div>
      <div class="pb-6">
        <UploadOneImage :image="{ src: config.banner_mobile }" @callback="getImageTrustBadgeMobile" />
      </div>
    </v-col>
    <v-col cols="12">
      <label class="mt-22 d-inline-block" for="">Banner link </label>
      <select-link :isCategory="true" :dataUrl="config.url" :itemSetData="config" @route="getRouteImage" />
    </v-col>
  </v-row>
</template>

<script>
import SelectLink from '../driver-theme64/SelectLink.vue';
export default {
  name: 'collection',
  components: {
    SelectLink,
  },
  props: {
    configSection: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
    };
  },
  methods: {
    getImageTrustBadgeDesktop(image) {
      this.config.banner_desktop = image.src;
      this.$parent.changeConfig();
    },
    getImageTrustBadgeMobile(image) {
      this.config.banner_mobile = image.src;
      this.$parent.changeConfig();
    },
    getRouteImage(nameRoute, item) {
      item.url = nameRoute;
      this.$parent.changeConfig();
    },
  },
  created() {
    this.config = this.configSection;
    if (this.config && !this.config.url) {
      this.config.url = {
        name: '',
        params: {
          slug: '',
        },
      };
    }
  },
};
</script>

<style></style>
