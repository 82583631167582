<template>
  <div class="form-component">
    <!-- Label -->
    <label class="mb-2 fz-14 d-block">
      {{ title }}
    </label>

    <!-- Text -->
    <small v-if="subtitle" class="form-text text-muted">
      <!-- {{ subtitle }} -->
    </small>

    <!-- WYSIWYG -->
    <!-- <Wysiwyg
      :value="$parent.attributes.bodyHtml"
      :callback="updateContent"
      :height="200"
      :plugins="['imageSelector']"
    ></Wysiwyg> -->
    <quill-editor
      v-model="contentEditor"
      class="editor-quill-custom"
      :options="{
        modules: {
          toolbar: toolbarOptions,
        },
      }"
      placeholder="..."
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    />
    <!-- <Editor :content.sync="$parent.attributes.bodyHtml" title="Mô tả" :mode="''" :required="false" /> -->
  </div>
</template>

<script type="text/javascript">
import event from '@/plugins/event-bus';
import imageHelper from '@/helpers/image';
import * as Quill from 'quill';

export default {
  props: {
    title: {
      type: String,
      default: 'Content',
    },
    subtitle: {
      type: String,
      default: '',
    },
    descriptionContent: {
      type: String,
      default: '',
    },
    model: {
      type: Array,
      default: () => [''],
    },
    content: {
      default: '',
    },
    update: {
      default: null,
    },
  },

  data() {
    let fontSizeStyle = Quill.import('attributors/style/size');
    // var ColorClass = Quill.import('attributors/class/color');

    fontSizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px'];
    // ColorClass.whitelist = [
    //   '#000000',
    //   '#e60000',
    //   '#ff9900',
    //   '#ffff00',
    //   '#008a00',
    //   '#0066cc',
    //   '#9933ff',
    //   '#ffffff',
    //   '#facccc',
    //   '#ffebcc',
    //   '#ffffcc',
    //   '#cce8cc',
    //   '#cce0f5',
    //   '#ebd6ff',
    //   '#bbbbbb',
    //   '#f06666',
    //   '#ffc266',
    //   '#ffff66',
    //   '#66b966',
    //   '#66a3e0',
    //   '#c285ff',
    //   '#888888',
    //   '#a10000',
    //   '#b26b00',
    //   '#b2b200',
    //   '#006100',
    //   '#0047b2',
    //   '#6b24b2',
    //   '#444444',
    //   '#5c0000',
    //   '#663d00',
    //   '#666600',
    //   '#003700',
    //   '#002966',
    //   '#3d1466',
    //   'custom-color',
    // ];
    Quill.register(fontSizeStyle, true);
    // Quill.register(ColorClass, true);

    return {
      imageHelper,
      contentEditor: '',
      toolbarOptions: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ size: fontSizeStyle.whitelist }],
        // [
        //   {
        //     color: [
        //       '#000000',
        //       '#e60000',
        //       '#ff9900',
        //       '#ffff00',
        //       '#008a00',
        //       '#0066cc',
        //       '#9933ff',
        //       '#ffffff',
        //       '#facccc',
        //       '#ffebcc',
        //       '#ffffcc',
        //       '#cce8cc',
        //       '#cce0f5',
        //       '#ebd6ff',
        //       '#bbbbbb',
        //       '#f06666',
        //       '#ffc266',
        //       '#ffff66',
        //       '#66b966',
        //       '#66a3e0',
        //       '#c285ff',
        //       '#888888',
        //       '#a10000',
        //       '#b26b00',
        //       '#b2b200',
        //       '#006100',
        //       '#0047b2',
        //       '#6b24b2',
        //       '#444444',
        //       '#5c0000',
        //       '#663d00',
        //       '#666600',
        //       '#003700',
        //       '#002966',
        //       '#3d1466',
        //       'custom-color',
        //     ],
        //   },
        // ],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
      ],
    };
  },
  watch: {
    contentEditor() {
      this.$emit('update:content', this.contentEditor);
      this.$emit('change');
    },
  },
  components: {},

  created() {
    this.contentEditor = this.content;
  },

  methods: {
    clear() {
      this.$v.$reset();
      this.contentComponent = '';
    },
    onEditorBlur(editor) {
      // console.log("editor blur!", editor);
    },
    onEditorFocus(editor) {
      // console.log("editor focus!", editor);
    },
    onEditorReady(editor) {
      //console.log("editor ready!", editor);
    },
    onEditorChange(editor) {
      console.log('áda', editor);
      //   this.contentComponent = html;
      //   this.$emit('update:content', html);
    },
    /**
     * Set page content attribute
     *
     * @param string
     */
    updateContent(html) {
      this.$parent.attributes.bodyHtml = html;
    },
  },
};
</script>
<style lang="scss">
.editor-quill-custom {
  .ql-container {
    .ql-editor {
      max-height: 500px;
    }
  }
  width: 100%;
}
.ql-picker-item[data-value='10px']::before,
.ql-picker-label[data-value='10px']::before {
  content: '10px' !important;
}

.ql-picker-item[data-value='12px']::before,
.ql-picker-label[data-value='12px']::before {
  content: '12px' !important;
}

.ql-picker-item[data-value='14px']::before,
.ql-picker-label[data-value='14px']::before {
  content: '14px' !important;
}

.ql-picker-item[data-value='16px']::before,
.ql-picker-label[data-value='16px']::before {
  content: '16px' !important;
}

.ql-picker-item[data-value='18px']::before,
.ql-picker-label[data-value='18px']::before {
  content: '18px' !important;
}

.ql-picker-item[data-value='20px']::before,
.ql-picker-label[data-value='20px']::before {
  content: '20px' !important;
}

.ql-picker-item[data-value='24px']::before,
.ql-picker-label[data-value='24px']::before {
  content: '24px' !important;
}

.ql-picker-item[data-value='30px']::before,
.ql-picker-label[data-value='30px']::before {
  content: '30px' !important;
}

.ql-picker-item[data-value='32px']::before,
.ql-picker-label[data-value='32px']::before {
  content: '32px' !important;
}

.ql-picker-item[data-value='36px']::before,
.ql-picker-label[data-value='36px']::before {
  content: '36px' !important;
}
.ql-color .ql-picker-options [data-value='custom-color'] {
  background: none !important;
  width: 100% !important;
  height: 20px !important;
  text-align: center;
}
.ql-color .ql-picker-options [data-value='custom-color']:before {
  content: 'Custom Color';
}
.ql-color .ql-picker-options [data-value='custom-color']:hover {
  border-color: transparent !important;
}
</style>
